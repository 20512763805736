.blog-form {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-actions {
  display: flex;
  justify-content: space-between; /* Distribute buttons to opposite ends */
  align-items: center;
  margin-top: 15px;
}

.blog-submit-btn,
.blog-view-btn {
  background-color: #6621BD;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  width: 48%; /* Ensure buttons are equal size */
  text-align: center;
}

.blog-submit-btn:hover,
.blog-view-btn:hover {
  background-color: #50179f;
}
