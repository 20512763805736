.h1-contribute-page{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 34px;
    text-align: center;
    color: black;
}

.h2-contribute-page{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    font-size: 20px;
    white-space: pre-line;
    line-height: 130%;
    color: black;
    margin: 14px 0;
}

.btn-contribute-page-yellow{
    background-color: #DCB30A;
	color: rgb(0, 0, 0);
    width: 269px;
	height: 47px;
	border: none;
	border-radius: 10px;
	font-size: 18px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 30px;
}

.btn-contribute-page-yellow:hover{
    background-color: #ffcc00;
}

.btn-contribute-page-yellow:active{
    background-color: #ac8a03;
}

.screen-container-contribute{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contribute-screen{
    padding: 20px 0 60px 0;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cons-card-contribute {
    width: 18%;
    box-sizing: border-box;
    padding: 10px;
    background: #ffcc00;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cons-card-contribute h2{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 700;
    font-size: 20px;
    white-space: pre-line;
    line-height: 130%;
    color: black;
    text-align: center;
}

.cons-card-contribute h3{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    font-size: 18px;
    white-space: pre-line;
    line-height: 130%;
    color: black;
    text-align: center;
}

.cons-card-contribute-ultimate {
    width: 40%;
    box-sizing: border-box;
    padding: 10px;
    background: #6621BD;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cons-card-contribute-ultimate h2{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 700;
    font-size: 20px;
    white-space: pre-line;
    line-height: 130%;
    color: white;
    text-align: center;
}

.cons-card-contribute-ultimate h3{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    font-size: 18px;
    white-space: pre-line;
    line-height: 130%;
    color: white;
    text-align: center;
}

.cons-contribute{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width:800px) {    
    .h2-contribute-page{
        text-align: center;
    }

    .contribute-screen{
        width: 90%;
    }

    .cons-contribute{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .cons-card-contribute {
        width: 80%;
        height: 100px;
        margin: 10px;
    }
    
    .cons-card-contribute h2{
        font-size: 17px;
    }
    
    .cons-card-contribute h3{
        font-size: 15px;
    }
    
    .cons-card-contribute-ultimate {
        width: 80%;
        height: 250px;
    }
    
    .cons-card-contribute-ultimate h2{
        font-size: 20px;
    }
    
    .cons-card-contribute-ultimate h3{
        font-size: 18px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
    .cons-card-contribute {
        width: 18%;
        height: 225px;
    }
    
    .cons-card-contribute h2{
        font-size: 17px;
    }
    
    .cons-card-contribute h3{
        font-size: 15px;
    }
    
    .cons-card-contribute-ultimate {
        width: 40%;
        height: 200px;
    }
    
    .cons-card-contribute-ultimate h2{
        font-size: 20px;
    }
    
    .cons-card-contribute-ultimate h3{
        font-size: 18px;
    }
}