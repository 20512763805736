.sales-page-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sales-page-top-canva {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.sales-page-top-canva-image {
    width: 100%;
}

.sales-page-top-message-box {
    width: 55%;
    height: 130px;
    background-color: #3D4CD8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-top-message-box-text-format {
    text-align: center;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 30px;
    color: #ffffff;
    display: inline;
}

.sales-page-top-message-box-text-format span {
    color: #FFD600;
    font-weight: 800;
}

.sales-page-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
}

.sales-page-video {
    width: 35%;
    height: 70%;
}

.sales-page-1-on-1-video-placeholder {
    width: 35%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    background-color: #000; /* Placeholder background */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-first-blue-canva {
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3D4CD8;
}

.sales-page-first-blue-canva-first-text {
    width: 100%;
    display: inline;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
}

.sales-page-first-blue-canva-schedule-button-container {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sales-page-first-blue-canva-schedule-button {
    width: 500px;
    height: 80px;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Poppins';
    background-color: #FFC300;
    border-radius: 100px;
    box-shadow: 2px 5px 0px rgb(255, 255, 255); /* x-offset y-offset blur-radius color */
    border: none;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: transform 0.1s, box-shadow 0.2s;
}

.sales-page-first-blue-canva-schedule-button:active {
    transform: translateY(2px);
    box-shadow: 0 1px 0 #FFF;
}

.sales-page-first-blue-canva-schedule-button:hover {
    background-color: #ffcf2f;
}

.sales-page-first-blue-canva-second-text {
    width: 100%;
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: #ffffff;
}

.sales-page-second-blue-and-white-canva-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 750px;
}

.sales-page-second-blue-and-white-canva-first-column {
    width: 33.33%;
    background-color: #3D4CD8;
    border-radius: 0 30px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-first-column-first-row {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-first-column-second-row {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-second-column {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-second-column-first-row {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-second-column-second-row {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-third-column {
    width: 33.33%;
    background-color: #3D4CD8;
    border-radius: 30px 0 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-third-column-first-row {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-second-blue-and-white-canva-third-column-second-row {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-first-white-canva-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sales-page-first-white-canva-container-title {
    font-size: 42px;
    font-weight: 800;
    color: #3D4CD8;
    padding-bottom: 50px;
}

.sales-page-first-white-canva-container-body {
    font-size: 32px;
    text-align: center;
    color: black;
    padding-bottom: 50px;
    font-weight: 300;
}

.sales-page-first-white-canva-container-body span {
    color: #3D4CD8;
    font-weight: 400;
}

.sales-page-first-white-canva-container-end {
    font-size: 32px;
    text-align: center;
    color: black;
    padding-bottom: 50px;
    font-weight: 400;
}

.sales-page-third-blue-canva-container::before {
    content: '';
    display: block;
    position: absolute;
    top: -20px; /* Adjust this value depending on the wave height */
    left: 0;
    width: 100%;
    height: 50px; /* Adjust this value depending on the wave height */
    background-repeat: repeat-x;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 40" preserveAspectRatio="none"><path d="M 0 10 L 0 5 Q 25 0, 50 5 T 100 5 L 100 10 Z" fill="%233D4CD8"/></svg>');
    background-size: 400px 100px;
}

.sales-page-third-blue-canva-container {
    height: 1370px;
    width: 100%;
    background-color: #3D4CD8;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
}

.sales-page-third-blue-canva-container p {
    margin-bottom: 50px;
}

.sales-page-third-blue-canva-container span {
    color: #EAB401;
    font-weight: 500;
}

.sales-page-forth-blue-canva-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.sales-page-forth-blue-canva-container-top {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #3D4CD8;
}

.sales-page-forth-blue-canva-middle-x {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 60px;
    color: white;
    font-family: 'Roboto Mono';
    font-weight: 100;
    line-height: 70px;
    background-color: #3D4CD8;
    z-index: -1;
}

.sales-page-forth-blue-canva-container-bottom {
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('../../images/salesPageDisplace.webp');
    background-size: cover; /* or a specific size like 100% 100% */
    background-position: center;
}

.sales-page-forth-blue-canva-container-bottom-testimony-block {
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.sales-page-forth-blue-canva-container-bottom-text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    text-align: center;
    color: #3D4CD8;
    font-family: 'Poppins';
    font-size: 30px;
    height: 100%;
    margin-bottom: 45px;
}

.sales-page-forth-blue-canva-container-bottom-text-block span {
    font-weight: 700;
    font-size: 34px;
}

.sales-page-forth-blue-canva-container-top-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 45px;
}

.sales-page-forth-blue-canva-container-top-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 45px;
}

.sales-page-forth-blue-canva-container-bottom-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-right: 45px;
}

.sales-page-forth-blue-canva-container-bottom-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 45px;
}

.sales-page-second-white-canva-container {
    height: 100%;
    width: 100%;
    background-color: #E8F2E5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sales-page-second-white-canva-black-title {
    height: 150px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    font-size: 36px;
    font-weight: 600;
    color: black;
}

.sales-page-second-white-canva-black-body {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    font-size: 22px;
    font-weight: 300;
    line-height: 45px;
    color: black;
}

.sales-page-second-white-canva-black-body p {
    margin-bottom: 40px;
}

.sales-page-second-white-canva-black-body p:nth-child(3) {
    font-weight: 600;
}

.sales-page-first-yellow-canva {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    background-color: transparent; /* Make the main div transparent */
  }
  
  .sales-page-first-yellow-canva:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: #FFC300; /* Your color */
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  }
  
  .sales-page-first-yellow-canva-white-title {
    height: 150px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    text-align: right;
    font-size: 36px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    margin-top: 10px;
}

.sales-page-first-yellow-canva-white-body {
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    text-align: right;
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    color: rgb(0, 0, 0);
    margin-bottom: 90px;
}

.sales-page-first-yellow-canva-white-body p {
    margin-bottom: 40px;
}

.sales-page-first-yellow-canva-white-body p:nth-child(2) {
    font-weight: 600;
}


.sales-page-second-yellow-canva {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    background-color: transparent; /* Make the main div transparent */
  }
  
  .sales-page-second-yellow-canva:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: #FFC300; /* Your color */
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
  }
  
  .sales-page-second-yellow-canva-white-title {
    height: 150px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    text-align: right;
    font-size: 36px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    margin-top: 10px;
}

.sales-page-second-yellow-canva-white-body {
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    text-align: right;
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    color: rgb(0, 0, 0);
}

.sales-page-second-yellow-canva-white-body p {
    margin-bottom: 40px;
}

.sales-page-second-yellow-canva-white-body p:nth-child(1) {
    font-weight: 600;
}

.sales-page-fifth-blue-canva-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 900px;
    padding: 60px;
    background-color: #3D4CD8;
}

.sales-page-fifth-blue-canva-title {
    font-size: 44px;
    color: white;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    margin-bottom: 30px;
}

.sales-page-fifth-blue-canva-title p:nth-child(2) {
    font-size: 24px;
    font-weight: 200;
    text-align: center;
}

.sales-page-fifth-blue-canva-pricing-cards {
    display: flex;
    width: 90%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.sales-page-fifth-blue-canva-pricing-cards-1, .sales-page-fifth-blue-canva-pricing-cards-2, .sales-page-fifth-blue-canva-pricing-cards-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    height: 100%;
}

.sales-page-third-yellow-canva {
    width: 100%;
    height: 200px;
    position: relative;
    padding: 20px;
    background-color: transparent; /* Make the main div transparent */
    font-size: 44px;
    font-weight: 700;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .sales-page-third-yellow-canva:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: #ffffff; /* Your color */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  }

.sales-page-sixth-blue-testimony-canva {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #9847FF;
    margin-top: -20px;
    width: 100%;
    height: 100%;
    padding: 80px 0;
    z-index: -2;
}

.sales-page-sixth-blue-testimony-canva-left {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    margin-right: 30px;
}

.sales-page-sixth-blue-testimony-canva-right {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    margin-left: 30px;
}

.sales-page-last-canva {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.sales-page-last-canva-title {
    text-align: center;
    font-size: 30px;
    color: black;
    font-weight: 700;
    margin: 50px 0;
}

.sales-page-last-canva-body {
    text-align: center;
    font-size: 32px;
    color: black;
    font-weight: 300;
}

.sales-page-last-canva-body span {
    font-weight: 700;
    color: #9847FF;
}

.sales-page-last-canva-schedule-button {
    width: 700px;
    height: 80px;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Poppins';
    background-color: #FFC300;
    border-radius: 100px;
    margin: 20px 0;
    box-shadow: 2px 5px 0px #9847FF; /* x-offset y-offset blur-radius color */
    border: none;
    cursor: pointer;
    transition: transform 0.1s, box-shadow 0.2s;
}

.sales-page-last-canva-schedule-button:active {
    transform: translateY(2px);
    box-shadow: 0 1px 0 #FFF;
}

.sales-page-last-canva-schedule-button:hover {
    background-color: #ffcf2f;
}

.sales-page-bottom-canva {
    width: 100%;
    height: 120px;
    background-color: #9847FF;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .sales-page-top-message-box {
        width: 90%;
        height: 80px;
    }

    .sales-page-top-message-box-text-format {
        font-size: 14px;
    }

    .sales-page-video-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 260px;
    }
    
    .sales-page-video {
        width: 80%;
        height: 70%;
    }

    .sales-page-first-blue-canva {
        height: 300px;
    }

    .sales-page-first-blue-canva-first-text {
        width: 90%;
        font-size: 16px;
    }

    .sales-page-first-blue-canva-schedule-button {
        width: 300px;
        height: 40px;
        font-size: 16px;
    }

    .sales-page-first-blue-canva-second-text {
        width: 90%;
        font-size: 18px;
    }

    .sales-page-second-blue-and-white-canva-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    
    .sales-page-second-blue-and-white-canva-first-column {
        width: 100%;
        background-color: #3D4CD8;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
    }
    
    .sales-page-second-blue-and-white-canva-first-column-first-row {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .sales-page-second-blue-and-white-canva-first-column-second-row {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .sales-page-second-blue-and-white-canva-second-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
    }
    
    .sales-page-second-blue-and-white-canva-second-column-first-row {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .sales-page-second-blue-and-white-canva-second-column-second-row {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .sales-page-second-blue-and-white-canva-third-column {
        width: 100%;
        background-color: #3D4CD8;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
    }
    
    .sales-page-second-blue-and-white-canva-third-column-first-row {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .sales-page-second-blue-and-white-canva-third-column-second-row {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sales-page-first-white-canva-container {
        padding: 30px;
    }
    
    .sales-page-first-white-canva-container-title {
        font-size: 36px;
    }
    
    .sales-page-first-white-canva-container-body {
        font-size: 22px;
    }
    
    .sales-page-first-white-canva-container-end {
        font-size: 22px;
    }

    .sales-page-third-blue-canva-container {
        height: 100%;
        font-size: 16px;
        padding: 40px 20px;
    }

    .sales-page-forth-blue-canva-container-top {
        height: 100%;
        width: 100%;
        flex-direction: column;
    }

    .sales-page-forth-blue-canva-container-top-left, .sales-page-forth-blue-canva-container-top-right {
        align-items: center;
    }

    .sales-page-forth-blue-canva-container-bottom-testimony-block {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .sales-page-forth-blue-canva-container-top-left {
        width: 100%;
        align-items: center;
        margin-right: 0px;
    }
    
    .sales-page-forth-blue-canva-container-top-right {
        width: 100%;
        align-items: center;
        margin-left: 0px;
    }
    
    .sales-page-forth-blue-canva-container-bottom-left {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
    }
    
    .sales-page-forth-blue-canva-container-bottom-right {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }

    .sales-page-forth-blue-canva-middle-x {
        display: none;
    }

    .sales-page-forth-blue-canva-container-bottom-text-block {
        font-size: 20px;
        margin-bottom: 45px;
    }

    .sales-page-forth-blue-canva-container-bottom-text-block span {
        font-size: 18px;
    }

    .sales-page-second-white-canva-black-title {
        font-size: 24px;
    }
    
    .sales-page-second-white-canva-black-body {
        font-size: 20px;
    }

    .sales-page-first-yellow-canva-white-title {
        font-size: 24px;
    }
    
    .sales-page-first-yellow-canva-white-body {
        font-size: 20px;
    }

    .sales-page-second-yellow-canva-white-title {
        font-size: 24px;
    }
    
    .sales-page-second-yellow-canva-white-body {
        font-size: 20px;
    }

    .sales-page-fifth-blue-canva-container {
        height: 100%;
    }

    .sales-page-fifth-blue-canva-pricing-cards {
        flex-direction: column;
        width: 100%;
        padding: 0px;
    }
    
    .sales-page-fifth-blue-canva-pricing-cards-1, .sales-page-fifth-blue-canva-pricing-cards-2, .sales-page-fifth-blue-canva-pricing-cards-3 {
        width: 100%;
        height: 100%;
    }

    .sales-page-third-yellow-canva {
        height: 100px;
        font-size: 24px;
    }

    .sales-page-sixth-blue-testimony-canva {
        flex-direction: column;
        padding: 60px 20px;
    }
    
    .sales-page-sixth-blue-testimony-canva-left {
        align-items: center;
        width: 90%;
        margin-right: 0px;
    }
    
    .sales-page-sixth-blue-testimony-canva-right {
        align-items: center;
        width: 90%;
        margin-left: 0px;
    }

    .sales-page-last-canva-schedule-button {
        width: 250px;
        height: 50px;
        font-size: 16px;
    }
    
    .sales-page-last-canva-title {
        font-size: 22px;
        margin: 20px 0;
    }
    
    .sales-page-last-canva-body {
        font-size: 16px;
    }

    .sales-page-last-canva {
        width: 90%;
        height: 100%;
        justify-content: center;
        padding: 20px;
    }
}