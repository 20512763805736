.screen-container-tree{
    overflow: hidden;
    max-height: 100vh;
}
.main-screen-container{
    width: 100%;
    display: flex;
    overflow: hidden; 
}
.main-screen-sidebar{
    width: 15%;
    top: 0;
    left: 0;
    height: 100%;
    position: fixed;
    background-image: url('../../images/patternSidebarTree.png'); /* Change this to the path of your image */
    background-position: center;
    background-repeat: no-repeat;
}
.main-screen-right{
    right: 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed; /* keep this fixed */
    z-index: 0;
    height: 100vh !important;
    overflow: hidden;
}

.main-screen-right-border{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1px solid rgba(235, 180, 0, 0.50);
    padding: 15px 0;
    margin: 5px 0;
    z-index: -1;
}

.join-discord-community{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 119%;
    color: #9847FF;
    text-align: center;
}

.join-discord-community-p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 119%;
    color: #9847FF;
    text-align: center;
}

.join-discord-div{
    width: 80%;
}
.join-discord-community-gray{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 119%;
    color: rgba(98, 98, 98, 1);
    margin: 0 1px;
    text-align: center;
}

.join-discord-community-gray-p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 119%;
    color: rgba(98, 98, 98, 1);
    margin: 0 1px;
    text-align: center;
}

.btn-join-discord{
    width: 218px;
    height: 32px;
    background: rgba(253, 181, 0, 1);
    border: rgba(253, 181, 0, 1);
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #626262;
    box-shadow: 0 3px 0 #9847FF;
}

.btn-login{
    width: 55%;
    height: 32px;
    background: rgba(253, 181, 0, 1);
    border: rgba(253, 181, 0, 1);
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;  
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #626262;
    box-shadow: 0 3px 0 #9847FF;
}

.btn-join-discord:hover, .btn-login:hover{
    background: rgba(255, 202, 68, 1);
    box-shadow: 0 3px 0 #9847FF;
}

.btn-join-discord:active, .btn-login:active{
    background: rgba(253, 181, 0, 1);
    box-shadow: none;
}

.btn-register{
    width: 55%;
    height: 32px;
    background: rgba(21, 18, 158, 1);
    border: rgba(21, 18, 158, 1);
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 3px 0 rgba(253, 181, 0, 1);
    margin-top: 10px;
}

.btn-register:hover{
    background: rgba(70, 67, 190, 1);
    box-shadow: 0 3px 0 rgba(253, 181, 0, 1);
}

.btn-register:active{
    background: rgba(21, 18, 158, 1);
    box-shadow: none;
}

.yellow-card-logged-in{
    width: 70%;
    background-color: #FDB500;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 78px;
}

.yellow-card-logged-in div{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px 0;
}

.yellow-card-logged-in div p{
    color: #6A6B6A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20%;
    padding-left: 5px;
    margin: 0;
}

.btn-logged-out{
    width: 90%;
    height: 32px;
    background: rgba(106, 107, 106, 1);
    border: rgba(106, 107, 106, 1);
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 3px 0 rgba(152, 71, 255, 1);
    margin-top: 3px;
}

.btn-logged-out:hover{
    background: rgba(121, 121, 121, 1);
    box-shadow: 0 3px 0 rgba(152, 71, 255, 1);
}

.btn-logged-out:active{
    background: rgba(106, 107, 106, 1);
    box-shadow: none;
}

.btn-icon-discord-main{
    width: 15px;
    height: 18px;
    margin-right: 6px;
}

.main-img-discord{
    margin: 0px 0;
    padding: 0;
    height: 200px;
}

.main-img-quiz{
    padding: 6px 0 14px 0;
    width: 50%;
}

.main-img-private{
    padding: 6px 0 14px 0;
    width: 70%;
    z-index: -10;
}

/* Tree */
.main-screen-tree{
    position: relative;
    left: 15%;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll; /* allow this to scroll */
    margin-top: 30px;
    height: 100vh; /* keep this the height of the viewport */
    padding-bottom: 250px;
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
    .main-screen-right{
        height: 600px;
    }

    .join-discord-community{
        font-size: 32px;
    }

    .join-discord-community-gray{
        font-size: 32px;
    }

    .btn-join-discord, .btn-register, .btn-logged-out, .btn-login{
        width: 300px;
        height: 40px;
        font-size: 22px;
        line-height: 28px;
    }


    .btn-icon-discord-main{
        width: 18px;
        height: 31px;
    }

    .main-img-discord{
        margin: 0px 0;
        height: 300px;
    }

    .main-img-quiz{
        margin: 0px 0;
        height: 150px;
    }
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .screen-container-tree{
        max-height: none;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    .main-screen-container{
        flex-direction: column;
        height: auto;
        overflow-y: auto; /* allow to scroll on mobile */
    }
    .main-screen-sidebar{
        display: none;
    }
    .main-screen-right{
        width: 100%;
        height: auto;
        margin: 20px 0;
        position: static; /* reset to static for mobile */
    }
    .main-screen-tree{
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        overflow: scroll;
        padding-bottom: 10px;
        height: auto;
    }

    .btn-logged-out{
        width: 90%;
    }
}