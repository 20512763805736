.blog-post-page {
  background: linear-gradient(135deg, #bcb96a, #6621bd 50%, #bcb96a);
  font-family: "Poppins", sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  position: relative;
}

.blog-post-image {
  width: 100%;
  max-width: 600px;
  margin: 15px auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.blog-post-page a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.blog-post-page a:hover {
  color: #faca52;
}

/* Blog Logo */
.blog-post-page .blog-logo {
  position: absolute;
  z-index: 10;
  margin-right: 71%;
  margin-top: 5%;
}

@keyframes bounceLogo {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  60% {
    transform: translateY(5px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Header Section */
.blog-post-page .blog-post-header {
  position: relative;
  width: 100vw;
  height: 55vh;
  overflow: hidden;
  z-index: 0; /* Lower z-index to avoid overlapping with the navbar dropdown */
}

.blog-post-page .blog-post-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.05);
  opacity: 0;
  animation: fadeInImage 2s ease-out forwards;
  z-index: 0; /* Ensure it is below the navbar */
  position: relative; /* Required for proper z-index behavior */
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.blog-post-page .blog-post-header-content {
  position: absolute;
  bottom: 20px;
  left: 10%;
  transform: translateY(50px);
  opacity: 0;
  color: white;
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  text-align: left;
  animation: fadeInSlideUp 0.8s ease-out forwards;
}

@keyframes fadeInSlideUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.blog-post-page .blog-post-title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}

.blog-post-page .blog-post-author {
  font-size: 1.2rem;
  color: #f0e68c;
}

/* Table of Contents */

.blog-post-table-of-contents {
  /* Only display if it has content */
  display: none;
}

.blog-post-table-of-contents:not(:empty) {
  display: block;
}

.blog-post-page .blog-post-table-of-contents {
  background-color: #6621bd;
  color: #ffffff;
  border-radius: 8px;
  padding: 40px;
  margin: 35px auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 32%;
  max-width: 800px;
  text-align: left;
  transform: translateY(20px);
  animation: fadeInCard 0.6s ease-out forwards;
}

.blog-post-page .blog-post-table-of-contents h2 {
  margin-bottom: 8%;
}

.blog-post-page .toc-main-topic {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  color: #ffffff;
  transition: color 0.3s ease;
}

.blog-post-page .toc-main-topic:hover {
  color: #faca52;
}

.blog-post-page .toc-main-topic.toc-last-item {
  border-bottom: none;
}

/* SVG Arrow */
.blog-post-page .toc-arrow {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  fill: #ffd700;
}

.blog-post-page .toc-expand-icon {
  cursor: pointer;
  color: #ffd700;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: auto;
  transition: transform 0.3s ease, color 0.3s ease;
}

.blog-post-page .toc-expand-icon:hover {
  transform: scale(1.2);
  color: #faca52;
}

/* Subtopics Styling in TOC */
.blog-post-page .blog-post-subtopics {
  list-style: none;
  margin-left: 20px;
  padding: 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out;
}

.blog-post-page .blog-post-subtopics.expanded {
  opacity: 1;
  max-height: 1000px;
  transform: scaleY(1);
}

.blog-post-page .blog-post-subtopics li {
  margin: 10px 0;
  font-size: 1.1rem;
  color: #ffffff;
  transition: color 0.3s ease;
}

.blog-post-page .blog-post-subtopics li:hover {
  color: #faca52;
}

.blog-post-page .blog-post-subtopics li a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/* Blog Content */
.blog-post-page .blog-post-content {
  width: 60%;
  max-width: 800px;
  margin: 65px auto;
  text-align: left;
}

.blog-post-page .blog-post-section-title {
  font-size: 2rem;
  color: #f0e68c;
  margin-top: 30px;
}

.blog-post-page .blog-post-content p[id^="subsection-"] {
  font-size: 1.5rem;
  color: #ffbb00;
  line-height: 1.8;
}

.blog-post-page .blog-post-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* Next Post Button */
.next-post-btn {
  position: fixed;
  bottom: 40px;
  right: 108px;
  background-color: #6621bd;
  color: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
}

.next-post-btn:hover {
  background-color: #50179f;
  transform: scale(1.1);
}

.next-post-btn:focus {
  outline: none;
}

/* Back Post Button */
.back-post-btn {
  position: fixed;
  bottom: 40px;
  right: 175px;
  background-color: #6621bd;
  color: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
}

.back-post-btn:hover {
  background-color: #50179f;
  transform: scale(1.1);
}

.back-post-btn:focus {
  outline: none;
}

.hidden {
  display: none !important; /* Ensure the button is completely removed */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .blog-post-page .blog-logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 1%;
    left: 25%;
    z-index: 10;
  }

  .back-post-btn,
  .next-post-btn {
    display: none;
  }

  .blog-post-page .blog-post-main-image {
    width: 100vw;
  }

  .blog-post-page .blog-logo img {
    width: 200px;
    max-width: 100%;
  }

  .blog-post-page .blog-post-content {
    width: 90%;
  }

  .blog-post-page .blog-post-table-of-contents {
    width: 85%;
    margin: 35px 0 -15px;
  }
}
