/* Unit Test */
.table{
  border: none;
}

.container-unit-test{
  max-height: 50%;
  min-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.unit-test-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
}

.unit-test-table-wrapper {
  width: 100%; /* Adjust as needed */
  overflow-x: auto;
}

.unit-test-table{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0px;
  text-indent: initial;
  border-spacing: 2px;
  border-collapse: collapse;
  width: 100%; /* Adjust as needed */
  min-width: 100%; /* Ensures the table will not shrink beyond the wrapper's width */
}

.unit-test-table-congrats{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0;
  text-indent: initial;
  border-spacing: 2px;
  border-collapse: collapse;
  width:385px; /* Adjust as needed */
  min-width: 100%; /* Ensures the table will not shrink beyond the wrapper's width */
}

tr{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  box-sizing: border-box;
}

.unit-test-th, .unit-test-td-title, .unit-test-td-expected, .unit-test-td-run, .unit-test-td-color {
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Table Head */
.unit-test-th{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
}

.unit-test-th-congrats{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
}

.unit-test-thead{
  width: 90%;
}

.unit-test-thead-congrats{
  width: 90%;
  height: 33px;
}

.unit-test-tr-th{
  width: 99%;
  background-color: #3B432C;
  border-radius: 12px 12px 0 0;
  box-shadow: 8px 4px 0 2px #FDB500;
}

.unit-test-tr-th-congrats{
  width: 99%;
  background-color: #3B432C;
  border-radius: 12px 12px 0 0;
}

/* Table Body */
.unit-test-tbody{
  background: #626262;
  border-radius: 0 0 12px 12px;
  width: 90%;
  max-height: 300px;
  padding-left: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 3px 3px 0 2px #FDB500;
}

.unit-test-tbody-congrats{
  background: #626262;
  border-radius: 0 0 12px 12px;
  width: 90%;
  max-height: 300px;
  padding-left: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

td, th.unit-test-td-title{
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  vertical-align: middle;
}

td, th.unit-test-td-title-congrats{
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  vertical-align: middle;
}

.unit-test-tr-td{
  width: 100%;
  border-bottom: 0.7px solid rgba(255, 255, 255, 0.5);
  padding: 0px 0;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.td-content-wrapper {
  white-space: pre-wrap; /* Wrap text within the cell */
  word-break: break-word; /* Break words when necessary */
  overflow-wrap: break-word; /* Wrap long words */
  max-width: 100%; /* Ensure the div doesn't overflow the table cell */
}

.unit-test-td-title, .unit-test-td-expected {
  overflow: auto; /* Or overflow: hidden; */
} 

.unit-test-td-title, .unit-test-th-title, th.unit-test-td-title{
  width: 40%;
}

.unit-test-td-expected, .unit-test-th-expected{
    width: 26%;
    text-align: center;
}

.unit-test-td-run, .unit-test-th-run{
    width: 26%;
    text-align: center;
}

.unit-test-td-color, .unit-test-th-color{
    width: 5%;
}

.unit-test-td-title-congrats, .unit-test-th-title-congrats, th.unit-test-td-title-congrats{
  width: 40%;
}

.unit-test-td-expected-congrats, .unit-test-th-expected-congrats{
    width: 26%;
    text-align: center;
}

.unit-test-td-run-congrats, .unit-test-th-run-congrats{
    width: 26%;
    text-align: center;
}

.unit-test-td-color-congrats, .unit-test-th-color-congrats{
    width: 5%;
    margin-right: 5px;
}

  
/* Mobile Screen */
@media screen and (max-width: 700px) {
  .unit-test-table, .unit-test-table-congrats {
    width: max-content;
  }

  .unit-test-th, .unit-test-th-congrats{
    font-size: 14px;
    line-height: 18px;
  }

  td, th.unit-test-td-title{
    font-size: 10px;
    line-height: 12px;
  }


  td, th.unit-test-td-title-congrats{
    font-size: 10px;
    line-height: 12px;
  }

  .container-unit-test{
    width: 100%;
  }
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {

  .unit-test-th-congrats{
    font-size: 28px;
    line-height: 32px;
  }

  td, th.unit-test-td-title{
    font-size: 15px;
    line-height: 15px;
  }


  td, th.unit-test-td-title-congrats{
    font-size: 15px;
    line-height: 15px;
  }

  .unit-test-thead-congrats{
    height: 53px;
  }

  .unit-test-table-congrats{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0;
    text-indent: initial;
    border-spacing: 2px;
    border-collapse: collapse;
    width:800px; /* Adjust as needed */
    min-width: 100%; /* Ensures the table will not shrink beyond the wrapper's width */
  }
}