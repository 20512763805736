/* NavBar */
.new-navbar-container {
    position: relative;
    top: 0;
    z-index: 1; /* Make sure the navbar is in front of the background image */
    width: 100%;
    height: 76px;
    background-color: #9847FF;
    border-radius: 0px;
    box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.222);
}

/*.new-navbar-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 76px;
    background-image: url('../../images/backgroundNavBar.webp'); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.1; 
    z-index: -1; 
}*/

.new-navbar-items{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 50px;
}

.navbar-private-tutoring-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 55%;
    height: 100%;
}

.navbar-private-tutoring-card a, .navbar-private-tutoring-card p{
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-private-tutoring-card p{
    cursor: pointer;
    margin-bottom: 0;
}

.navbar-private-tutoring-card p:hover, .navbar-private-tutoring-card a:hover{
    background-color: #a86cf7; 
}

.navbar-private-tutoring-msg{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.navbar-private-tutoring-msg p{
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 128%;
    text-align: right;
    color: #FFFFFF;
}

.navbar-private-tutoring-msg p strong {
    margin-left: 5px; /* adjust as needed */
}

.navbar-btn{
    width: 136px;
    height: 32px;
    background: #DCB30A;
    border-radius: 12px;
    border: 1px solid #DCB30A;
}

.navbar-btn:hover{
    background: #ffcc00;
    border: 1px solid #ffcc00;
}

.navbar-btn:active{
    background: #ac8a03;
    border: 1px solid #ac8a03;
}

.navbar-btn a{
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 6px;
}

.navbar-btn img{
    width: 18px;
    height: 18px;
}

.navbar-btn p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(98, 98, 98, 1);
}

.navbar-mobile-icon{
    display: none;
}

.navbar-dropdown-menu {
    position: absolute;
    top: 60px; /* Adjust based on your navbar height */
    right: 0;
    background-color: #9847FF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 10px;
    z-index: 10;
}

.navbar-dropdown-menu a {
    padding: 10px;
    text-decoration: none;
    color: white;
    transition: background-color 0.3s;
    font-family: Poppins;
    font-size: 16px;
}

.navbar-dropdown-menu a:hover {
    background-color: #b88bf2;
}

/* Mobile Screen */
@media screen and (max-width: 700px) {
    .navbar-mobile-icon{
        display: block;
    }

    .navbar-private-tutoring-card-hide{
        display: none;
    }

    .navbar-private-tutoring-card{
        display: none;
        flex-direction: row;
        width: 55%;
    }

    .navbar-private-tutoring-card a, .navbar-private-tutoring-card p{
        font-size: 12px;
    }

    .navbar-private-tutoring-msg p{
        font-size: 10px;
        line-height: 128%;
    }

    .navbar-private-tutoring-msg p strong {
        margin-left: 1px; /* adjust as needed */
    }

    .navbar-btn{
        width: 80px;
        height: 32px;
    }

    .navbar-btn a{
        height: 20px;
        margin-top: 6px;
    }
    
    .navbar-btn img{
        width: 12px;
        height: 12px;
    }
    
    .navbar-btn p{
        font-size: 8px;
        line-height: 10px;
    }

    .newCodingDorsIcon{
        width: 100px;
    }

    .new-navbar-items{
        margin: 0 25px;
    }
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
    .navbar-private-tutoring-msg p{
        font-size: 20px;
        line-height: 28px;
    }

    .navbar-btn p{
        font-size: 15px;
        line-height: 100%;
    }

    .navbar-btn{
        width: 180px;
        height: 40px;
    }
    
    .navbar-btn a{
        height: 40px;
    }
    
    .navbar-btn img{
        width: 22px;
        height: 22px;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
    .navbar-private-tutoring-msg p{
        font-size: 26px;
        line-height: 130%;
    }

    .navbar-btn p{
        font-size: 20px;
        line-height: 100%;
    }

    .navbar-btn{
        width: 230px;
        height: 44px;
    }
    
    .navbar-btn a{
        height: 44px;
    }
    
    .navbar-btn img{
        width: 24px;
        height: 24px;
    }
}