.sales-page-faq-cards-container {
    background-color: white;
    border-radius: 20px;
    box-shadow: 4px 4px 15px #00000032;
    width: 100%;
    height: 200px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sales-page-faq-cards-title {
    width: 90%;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    color: black;
    margin-bottom: 25px;
}

.sales-page-faq-cards-body {
    width: 90%;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    color: black;
    line-height: 30px;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .sales-page-faq-cards-title {
        width: 90%;
        font-size: 16px;
    }
    
    .sales-page-faq-cards-body {
        width: 90%;
        font-size: 13px;
        line-height: 22px;
    }
}