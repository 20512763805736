.sales-page-pricing-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    width: 90%;
    height: 100%;
    transition: transform 0.2s;
}

.sales-page-pricing-cards-container:hover {
    transform: scale(1.02);
}

.sales-page-pricing-cards-most-common {
    font-size: 40px;
    text-align: center;
    font-weight: 800;
    color: black;
    line-height: 36px;
    margin-bottom: 50px;
}

.sales-page-pricing-cards-price {
    font-size: 90px;
    color: black;
    font-weight: 800;
    text-align: center;
    line-height: 30px;
    margin-bottom: 50px;
}

.sales-page-pricing-cards-price span {
    font-weight: 500;
    font-size: 24px;
}

.sales-page-pricing-cards-benefits {
    font-size: 22px;
    color: black;
    font-weight: 500;
    margin-bottom: 30px;
}

.checkmark::before {
    content: '✔'; /* Checkmark character */
    padding-right: 10px; /* Space between the checkmark and the text */
    color: black; /* Color of the checkmark */
    /* Additional styling to match your design */
}

ul {
    list-style-type: none; /* This removes the bullet points */
    padding-left: 0; /* This removes the default padding on the left side */
}

.sales-page-pricing-cards-button {
    color: #000000;
    background-color: #FFC300;
    box-shadow: 3px 4px 0px #3D4CD8;
    font-size: 20px;
    font-weight: 700;
    border: none;
    border-radius: 10px;
    width: 180px;
    height: 70px;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: transform 0.1s, box-shadow 0.2s;
}

.sales-page-pricing-cards-button:active {
    transform: translateY(2px);
    box-shadow: 0 1px 0 #FFF;
}

.sales-page-pricing-cards-button:hover {
    background-color: #ffcf2f;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .sales-page-pricing-cards-container {
        width: 100%;
        height: 100%;
        padding: 40px;
        margin-bottom: 30px;
    }
    
    .sales-page-pricing-cards-most-common {
        font-size: 24px;
        margin-bottom: 20px;
    }
    
    .sales-page-pricing-cards-price {
        font-size: 70px;
        margin-bottom: 25px;
    }

    .sales-page-pricing-cards-price span {
        font-size: 18px;
    }
    
    .sales-page-pricing-cards-benefits {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .sales-page-pricing-cards-button {
        height: 30px;
        font-size: 14px;
    }
}