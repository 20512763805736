.automations-sales-page-screen{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header */

.automations-sales-page-header{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.automations-sales-page-header img{
    margin: 50px 0px 50px 0px;
}

.automations-sales-page-header-line{
    width: 100%;
    height: 20px;
    background-color: black;
}

.automations-sales-page-header-title{
    width: 50%;
}

.automations-sales-page-header-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #ffae00;
    padding: 40px;
    border-top: 1px solid black;
}

.automations-sales-page-header-text p{
    text-align: center;
    width: 60%;
    font-size: 30px;
    color: black;
    font-weight: 300;
    font-family: 'Poppins';
    line-height: 50px;
}

/* Header 2 */

.automations-sales-page-header2-line{
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.automations-sales-page-header2-line-block1{
    padding: 60px;
    width: 50%;
}

.automations-sales-page-header2-line-block1 h1{
    text-align: right;
    color: white;
    font-size: 40px;
    font-weight: 400;
}

.automations-sales-page-header2-line-block1 h1:nth-child(2){
    font-size: 30px;
}

.automations-sales-page-header2-line-block2{
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 50%;
}

.automations-sales-page-header2-line-block2 img{
    width: 40%;
    margin: 0;
    padding: 0;
}

.automations-sales-page-header2-line2{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    background-image: url('../../images/automationsSalesPage/diagram.webp');
    background-position-y: center;
    background-size: cover;
}

.automations-sales-page-header2-line2-image1{
    display: flex;
    align-items: center;
    padding: 60px;
    width: 60%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.621);
}

.automations-sales-page-header2-line2-image1 p{
    font-size: 32px;
    line-height: 60px;
    color: white;
    font-family: 'Poppins';
}

.automations-sales-page-header2-line3{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: right;
    flex-direction: row;
    align-items: center;
    background-image: url('../../images/automationsSalesPage/n8n.webp');
    background-position-y: top;
    background-size: 50%;
    background-repeat: no-repeat;
    background-color: #000000;
    border-bottom: 0.1px solid white;
}

.automations-sales-page-header2-line2-image2{
    display: flex;
    align-items: center;
    padding: 60px;
    width: 50%;
    height: 100%;
}

.automations-sales-page-header2-line2-image2 p{
    font-size: 32px;
    line-height: 60px;
    color: white;
    font-family: 'Poppins';
    text-align: right;
}

.automations-sales-page-header2-part2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 60px;
    background-color: black;
}

.automations-sales-page-header2-part2 p{
    font-size: 60px;
    font-weight: 400;
    color: rgb(255, 255, 255);
}

.automations-sales-page-header2-part2-cards{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: left;
    background-color: white;
}

.automations-sales-page-header2-card1{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-right: 0.1px solid black;
}

.automations-sales-page-header2-card-img{
    width: 100%;
    height: 25%;
    background-image: url('../../images/automationsSalesPage/flowDiagram.webp');
    background-position-y: 20%;
    background-size: cover;
}

.automations-sales-page-header2-card-img2{
    width: 100%;
    height: 25%;
    background-image: url('../../images/automationsSalesPage/advancedAutomations.webp');
    background-position-y: 30%;
    background-size: cover;
}

.automations-sales-page-header2-card-img3{
    width: 100%;
    height: 25%;
    background-image: url('../../images/automationsSalesPage/support.webp');
    background-position-y: 40%;
    background-size: cover;
}

.automations-sales-page-header2-card-img4{
    width: 100%;
    height: 25%;
    background-image: url('../../images/automationsSalesPage/results.webp');
    background-position-y: center;
    background-size: cover;
}

.automations-sales-page-header2-card-text{
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.automations-sales-page-header2-card-text p{
    text-align: center;
    font-size: 24px;
    color: black;
    font-family: 'Poppins';
}

.automations-sales-page-header2-part3{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 60px;
    background-color:#ffae00
}

.automations-sales-page-header2-part3 p{
    font-size: 60px;
    font-weight: 400;
    color: rgb(0, 0, 0);
}

.automations-sales-page-header2-part4{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #9847FF;
}

.automations-sales-page-header2-part4-column{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
    width: 50%;
    height: 100%;
}

.automations-sales-page-header2-part4-column p{
    font-size: 30px;
    color: black;
}

.automations-sales-page-header2-part4-column2{
    padding: 80px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.automations-sales-page-header2-part4-column2 p{
    font-size: 30px;
    font-weight: 400;
    color: black;
    font-family: 'Poppins';
}

.automations-sales-page-header2-part4-column2 button{
    width: 70%;
    height: 60px;
    border: none;
    background-color: white;
    border-radius: 20px;
    color: black;
    font-size: 26px;
    font-weight: 600;
    box-shadow: black 2px 4px;
    transition: background-color 0.3s ease;
    transition: all 0.3s ease;
}

.automations-sales-page-header2-part4-column2 button:hover{
    background-color: #ffae00;
    color: rgb(255, 255, 255);
    box-shadow: rgb(255, 255, 255) 0px 0px;
}

@media screen and (max-width: 700px) {
    .automations-sales-page-header-title{
        width: 80%;
    }

    .automations-sales-page-header-text{
        padding: 10px;
    }

    .automations-sales-page-header-text p{
        width: 80%;
        font-size: 20px;
        line-height: 40px;
    }

    .automations-sales-page-header2-part4{
        flex-direction: column;
        justify-content: center;
    }

    .automations-sales-page-header2-part4-column{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
    }

    .automations-sales-page-header2-part4-column p{
        font-size: 20px;
        text-align: center;
    }

    .automations-sales-page-header2-part4-column2{
        width: 100%;
        padding: 0px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        align-items: center;
    }

    .automations-sales-page-header2-part4-column2 p{
        font-size: 20px;
    }

    .automations-sales-page-header2-part4-column2 button{
        width: 100%;
        padding: 30px;
        display: flex;
        align-items: center;
    }

    .automations-sales-page-header2-line{
        flex-direction: column;
    }

    .automations-sales-page-header2-line-block1{
        width: 100%;
        padding: 40px;
    }

    .automations-sales-page-header2-line-block1 h1{
        font-size: 30px;
    }

    .automations-sales-page-header2-line-block2{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 0px;
    }

    .automations-sales-page-header2-line-block2 img{
        width: 50%;
    }

    .automations-sales-page-header2-line2{
        flex-direction: column;
        justify-content: flex-start;
    }

    .automations-sales-page-header2-line2-image1{
        width: 100%;
        height: 80%;
        padding: 30px;
    }

    .automations-sales-page-header2-line2-image1 p{
        font-size: 20px;
        line-height: 40px;
        text-align: center;
    }

    .automations-sales-page-header2-line3{
        flex-direction: column;
        justify-content: flex-start;
        background-size: cover;
        background-position-x: center;
    }

    .automations-sales-page-header2-line2-image2{
        width: 100%;
        height: 80%;
        padding: 30px;
        background-color: #000000a5;
    }

    .automations-sales-page-header2-line2-image2 p{
        font-size: 20px;
        line-height: 40px;
        text-align: center;
    }

    .automations-sales-page-header2-part2{
        padding: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .automations-sales-page-header2-part2 p{
        font-size: 30px;
        text-align: center;
    }

    .automations-sales-page-header2-part2-cards{
        flex-direction: column;
        height: 100%;
        align-items: center;
    }

    .automations-sales-page-header2-card1{
        width: 100%;
        justify-content: center;
    }

    .automations-sales-page-header2-card1 img{
        width: 100%;
        height: 25%;
    }

    .automations-sales-page-header2-card-text p{
        font-size: 20px;
    }

    .automations-sales-page-header2-part3{
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .automations-sales-page-header2-part3 p{
        font-size: 30px;
        text-align: center;
    }
}