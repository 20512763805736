.proficiency-test-screen{
    background-color: white;
    height: 100vh;
}

.container-proficiency-intro{
    display: flex;
    flex-direction: column;
    margin: 10px 50px;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.card-for-logo{
    width: 758px;
    height: 130px;
    background: #626262;
    border-radius: 25px;
    margin: 43px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.card-for-logo img{
    width: 400px;
    height: 130px;
}

.container-text-intro{
    display: flex;
    flex-direction: column;
}

.container-proficiency-intro h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #626262;
}

.container-proficiency-intro p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #626262;
    margin: 3px;
}

.container-proficiency-intro p strong{
    margin: 0 2px;
}

.container-proficiency-intro h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #626262;
}

.start-test{
    margin-top: 36px;
    width: 384px;
    height: 49px;
    background: #9847FF;
    border: 1px solid #9847FF;
    border-radius: 12px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

/* Question */
.proficiency-test-question-screen{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.proficiency-test-question-screen h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    color: #000000;
    margin: 23px 0;
}

.proficiency-test-question-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 948px;
    height: 597px;
    background-color: #9847FF;
    border-radius: 25px;
}

.proficiency-test-exercise-counter{
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
}

.proficiency-test-question{
    height: 520px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}

.proficiency-test-question-card-left, .proficiency-test-question-card-right{
    width:  50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 27px;
}

.proficiency-test-question-left-h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FDB500;
}

.proficiency-test-question-left-h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
}

.proficiency-test-question-left-p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
}

.proficiency-test-question-line{
    height: 90%;
    border: 0.5px solid #E8F2E5;
}

.next-test{
    margin-top: 10px;
    width: 50%;
    height: 42px;
    background: #9847FF;
    border: 1px solid #9847FF;
    border-radius: 12px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
}

.proficiency-test-timer{
    background: #9847FF;
    border-radius: 12px;
    width: 100%;
    height: 42px;
    margin-bottom: 26px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.feedback-proficiency{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 15px;
}

.div-btn-msg-proficiency-question{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}

/* Results */
.container-text-result{
    margin-top: 26px;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.container-text-result-left, .container-text-result-right{
    width: 45%;
}

.container-text-result-left p, .container-text-result-right p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #626262;
}

.container-text-result-left p{
    text-align: right;
}

.container-text-result-right p{
    text-align: left;
}

.proficiency-result-card-next-week{
    width: 314px;
    height: 77px;
    background: linear-gradient(309.82deg, #EBB400 -49.74%, #9847FF 92.05%);
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin: 18px 0;
}

.proficiency-result-card-next-week h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 15px;
    padding-top: 10px;
}

.proficiency-result-card-next-week p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    text-decoration-line: underline;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 15px;
}

.proficiency-result-score-div{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.proficiency-result-score-div-left{
    width: 30%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #626262;
}

.card-for-logo h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FDB500;
}

.proficiency-result-line{
    width: 0px;
    height: 120px;
    border: 3px solid #E8F2E5;
    margin-right: 22px;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .card-for-logo{
        width: 90%;
        height: 200px;
    }

    .card-for-logo img{
        width: 100%;
        height: 45%;
    }

    .container-proficiency-intro h6{
        font-size: 22px;
        line-height: 26px;
        text-align: center;
    }
    
    .container-proficiency-intro p{
        font-size: 18px;
        line-height: 28px;
        margin: 3px;
        text-align: center;
    }
    
    .start-test{
        width: 90%;
        height: 59px;
        border-radius: 12px;
        font-size: 28px;
    }

    .container-proficiency-intro p strong{
        margin: 0 4px;
    }
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
    .card-for-logo{
        width: 858px;
        height: 217px;
    }

    .container-proficiency-intro h6{
        font-size: 34px;
        line-height: 48px;
    }
    
    .container-proficiency-intro p{
        font-size: 28px;
        line-height: 42px;
        margin: 3px;
    }
    
    .start-test{
        width: 484px;
        height: 69px;
        border-radius: 12px;
        font-size: 34px;
    }

    .proficiency-test-question-screen h1{
        font-size: 38px;
        line-height: 45px;
        margin: 46px 0;
    }
    
    .proficiency-test-question-card{
        width: 1200px;
        height: 800px;
    }
    
    .proficiency-test-exercise-counter{
        height: 100px;
        font-size: 28px;
        line-height: 36px;
    }
    
    .proficiency-test-question{
        height: 800px;
    }
    
    .proficiency-test-question-left-h2{
        font-size: 30px;
        line-height: 48px;
    }
    
    .proficiency-test-question-left-h3{
        font-size: 28px;
        line-height: 50px;
    }
    
    .proficiency-test-question-left-p{
        font-size: 24px;
        line-height: 40px;
    }
    
    .next-test{
        height: 59px;
        border-radius: 12px;
        font-size: 28px;
    }
    
    .proficiency-test-timer{
        height: 59px;
        border-radius: 12px;
        font-size: 28px;
    }

    /* Results */
    .container-text-result-left p, .container-text-result-right p{
        font-size: 35px;
        line-height: 130%;
    }

    .proficiency-result-card-next-week{
        width: 414px;
        height: 97px;
        margin: 40px 0;
    }

    .proficiency-result-card-next-week h6{
        font-size: 30px;
        padding-left: 30px;
    }

    .proficiency-result-card-next-week p{
        font-size: 22px;
        padding-left: 30px;
    }

    .proficiency-result-score-div-left{
        font-size: 45px;
        line-height: 130%;
    }

    .card-for-logo h1{
        font-size: 60px;
    }

    .container-proficiency-intro h1{
        font-size: 50px;
    }
}