.screen-container-codehelp{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div-code-help{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.div-code-help-h1{
    font-size: 30px;
    color: black;
    font-family: 'Poppins';
}

.div-code-help-h2{
    font-size: 24px;
    color: black;
    font-family: 'Poppins';
}

.card-steps-code-help {
    width: 40%;
    box-sizing: border-box;
    padding: 10px;
    background: #6621BD;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    min-height: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.card-steps-code-help-yellow{
    width: 40%;
    box-sizing: border-box;
    padding: 10px;
    background: #ffcc00;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.card-steps-code-help h2{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 700;
    font-size: 20px;
    white-space: pre-line;
    line-height: 28px;
    color: white;
    text-align: center;
}

.card-steps-code-help-yellow h2{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 700;
    font-size: 20px;
    white-space: pre-line;
    line-height: 28px;
    color: black;
    text-align: center;
}

.card-steps-code-help h3 {
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    font-size: 18px;
    white-space: pre-line;
    line-height: 26px;
    color: white;
    text-align: center;
    width: 85%;
}

.card-steps-code-help h3 a{
    color: #ffcc00;
    text-decoration: underline;
}

.card-steps-code-help-yellow h3{
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    font-size: 18px;
    white-space: pre-line;
    line-height: 26px;
    color: black;
    text-align: center;
    width: 85%;
}

@media screen and (max-width:800px) {    
    .card-steps-code-help, .card-steps-code-help-yellow {
        width: 90%;
        height: 250px;
    }
    
    .card-steps-code-help h2, .card-steps-code-help-yellow h2{
        font-size: 21px;
    }
    
    .card-steps-code-help h3, .card-steps-code-help-yellow h3{
        font-size: 17px;
    }

    .div-code-help{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
    }
    
    .div-code-help-h1{
        font-size: 28px;
    }
    
    .div-code-help-h2{
        font-size: 16px;
        width: 90%;
        text-align: center;
    }

    .div-code-help p{
        width: 90%;
        text-align: center;
    }
}