.code-solutions-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.code-solutions-top-mold {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover; /* or any other adjustments */
}

.code-solutions-top-mold img {
    width: 100%;
    position: relative;
    top: -15px;
}

.code-solutions-top-mold-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 85%;
    position: absolute;
    padding-top: 20px;
}

.code-solutions-top-mold-icon {
    display: flex;
    width: 18%;
}

.code-solutions-top-mold-icon-container p {
    font-family: 'Poppins';
    text-align: right;
    font-size: 45px;
    line-height: 45px;
    color: black;
    font-weight: 600;
    padding-right: 30px;
    padding-bottom: 20px;
    rotate: 3deg;
}

.code-solutions-first-p {
    text-align: center;
    font-family: 'Poppins';
    color: black;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
}

.code-solutions-first-p span {
    font-weight: 700;
    color: #9847FF;
}

.code-solutions-top-icons-container {
    width: 35%;
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.code-solutions-top-icons-container img {
    width: 65px;
}

.code-solutions-second-p {
    text-align: center;
    font-family: 'Poppins';
    color: black;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
}

.code-solutions-second-p span {
    font-weight: 700;
    color: #9847FF;
}

.code-solutions-private-button-container {
    width: 100%;
    height: 100%;
    margin-top: 35px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.code-solutions-private-button {
    width: 450px;
    height: 65px;
    font-size: 28px;
    font-weight: 700;
    font-family: 'Poppins';
    background-color: #424AE5;
    color: white;
    border-radius: 20px;
    box-shadow: 0px 7px 0px #626262; /* x-offset y-offset blur-radius color */
    border: none;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: transform 0.1s, box-shadow 0.2s;
}

.code-solutions-private-button:active {
    transform: translateY(6px);
    box-shadow: 0 1px 0 #626262;
}

.code-solutions-private-button:hover {
    background-color: #3139d3;
}

.code-solutions-card-columns-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.code-solutions-card-columns-container h1 {
    padding: 60px;
    font-family: 'Poppins';
    text-align: center;
    font-weight: 700;
    color: white;
}

.code-solutions-card-columns-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    height: 120%;
    background-color: #626262;
}

.code-solutions-card-columns-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    height: 120%;
    background-color: #5A5A5A;
}

.code-solutions-card-columns-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    height: 120%;
    background-color: #626262;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .code-solutions-top-mold img {
        top: 0px;
        margin-bottom: 20px;
    }

    .code-solutions-top-mold-icon-container {
        padding-top: 5px;
    }

    .code-solutions-top-mold-icon-container p {
        font-size: 15px;
        line-height: 18px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .code-solutions-first-p,
    .code-solutions-second-p {
        font-size: 16px;
        line-height: 20px;
    }

    .code-solutions-top-icons-container {
        width: 70%;
        margin-top: 20px;
        margin-bottom: 35px;
    }

    .code-solutions-top-icons-container img {
        width: 40px;
    }

    .code-solutions-private-button {
        width: 300px;
        height: 40px;
        border-radius: 10px;
        font-size: 16px;
    }

    .code-solutions-private-button-container {
        margin-top: 15px;
        margin-bottom: 50px;
    }

    .code-solutions-card-columns-container {
        flex-direction: column;
    }

    .code-solutions-card-columns-left,
    .code-solutions-card-columns-center,
    .code-solutions-card-columns-right {
        width: 100%;
    }

    .code-solutions-card-columns-container h1 {
        padding: 30px;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
    .code-solutions-first-p,
    .code-solutions-second-p {
        font-size: 50px;
        line-height: 80px;
    }

    .code-solutions-top-mold-icon-container p {
        font-size: 60px;
        line-height: 70px;
    }

    .code-solutions-top-icons-container {
        width: 50%;
    }

    .code-solutions-top-icons-container img {
        width: 130px;
    }

    .code-solutions-private-button-container {
        margin-top: 60px;
        margin-bottom: 100px;
    }

    .code-solutions-private-button {
        width: 1000px;
        height: 100px;
        font-size: 40px;
    }
}