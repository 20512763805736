.contact-page-message {
    width: 90%;
    height: 200px;
    padding: 10px;
    background-size: 22px;
    background-color: #ebb428;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.contact-page-message:hover {
    background-color: #ebb428;
    transition-duration: 20ms;
}

.contact-page-message:focus {
    background-color: #ebb428;
}

.contact-page-message::placeholder {
    color: #ffffff;
    font-size: 12px;
}

.contact-page-email {
    width: 90%;
    height: 47px;
    padding-left: 40px;
    background: url("../../images/emailIcon.svg") no-repeat scroll 8px 12px;
    background-size: 22px;
    background-color: #ebb428;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;

}

.contact-page-email:hover {
    background-color: #faca52;
    transition-duration: 20ms;
}

.contact-page-email:focus {
    background-color: #ebb428;
}

.contact-page-email::placeholder {
    color: #ffffff;
    font-size: 12px;
}

.contact-card {
    width: 60%;
    box-sizing: border-box;
    padding: 10px;
    background: #6621BD;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-page-button {
    width: 368px;
    height: 20px;
    background-color: white;
    border-radius: 12px;
    border-color: transparent;
    font-size: 16px;
    font-family: 'Roboto Flex';
    color: #9847FF;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.contact-page-button:hover {
    background-color: rgb(240, 231, 253);
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .contact-card {
        width: 80%;
        height: 250px;
    }
    .contact-page-button {
        width: 250px;
        height: 20px;
        font-size: 16px;
        border-radius: 4px
    }

    .contact-page-email{
        padding-left: 10px;
        background: none;
        background-color: #ebb428;
    }
}

@media screen and (min-width: 1800px){
    .contact-page-message {
        width: 500px;
        font-size: 20px;
    }

    .contact-page-message::placeholder {
        font-size: 18px;
    }

    .contact-page-email {
        width: 500px;
        font-size: 20px;
    }

    .contact-page-email::placeholder {
        font-size: 18px;
    }

    .contact-page-button {
        width: 500px;
        height: 70px;
        font-size: 22px;
    }
}