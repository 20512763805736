.inner-code-solutions-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.inner-code-solutions-top-mold {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover; /* or any other adjustments */
}

.inner-code-solutions-top-mold img {
    width: 100%;
    position: relative;
    top: -15px;
}

.inner-code-solutions-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.inner-code-solutions-h1 {
    font-size: 50px;
    font-weight: 700;
    color: #9847FF;
    line-height: 40px;
}

.inner-code-solutions-h2 {
    font-size: 36px;
    font-weight: 300;
    color: black;
}

.inner-code-solutions-lines {
width: 100%;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .inner-code-solutions-text-container {
        margin-bottom: 0px;
        margin: 10px;
    }

    .inner-code-solutions-top-mold img {
        top: 0px;
    }

    .inner-code-solutions-h1 {
        font-size: 35px;
    }

    .inner-code-solutions-h2 {
        font-size: 20px;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
    .inner-code-solutions-h1 {
        font-size: 70px;
    }

    .inner-code-solutions-h2 {
        font-size: 60px;
    }
}