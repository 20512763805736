.thank-you-page-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #626262;
}

.thank-you-page-background-lines {
    position: absolute;
    width: 100%;
    height: 128%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    opacity: 10%;
    image-rendering: optimizeQuality;
    z-index: -1;
}

.thank-you-page-dors-logo {
    width: 480px;
    position: absolute;
    margin: -70px 0;
}

.thank-you-page-rectangle {
    height: 950px;
    width: 600px;
    background-color: #FDB500;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 90px 0;
    border-radius: 0 0 10px 10px;
}

.thank-you-page-first-paragraph {
    font-size: 28px;
    font-weight: 800;
    line-height: 26px;
    color: #ffffff;
    text-align: center;
    margin: 80px 0 22px 0;
}

.thank-you-page-first-paragraph p:nth-child(2) {
    font-size: 24px;
    color: #9847FF;
}

.thank-you-page-second-paragraph {
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #9847FF;
}

.thank-you-page-middle-line {
    border-bottom: 1px solid #ffffff;
    width: 65%;
    margin: 26px 0 38px 0;
}

.thank-you-page-third-paragraph {
    font-size: 24px;
    font-weight: 800;
    line-height: 22px;
    text-align: center;
    color: #444444;
    margin: 0 0 26px 0
}

.thank-you-page-third-paragraph p:nth-child(3), .thank-you-page-third-paragraph p:nth-child(4) {
    font-size: 22px;
    font-weight: 600;
    color: #444444;
}

.thank-you-page-video-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 300px;
}

.thank-you-page-quiz-video {
    width: 500px;
    height: 100%;
    border-radius: 10px;
}

.thank-you-page-quiz-button {
    width: 420px;
    height: 55px;
    background-color: #444444;
    border-radius: 5px;
    border-color: transparent;
    font-size: 20px;
    font-family: 'Roboto Flex';
    color: #ffffff;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 45px;
}

.thank-you-page-quiz-button:hover {
    background-color: #333333;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .thank-you-page-dors-logo {
        width: 330px;
    }

    .thank-you-page-background-lines {
        display: none;
    }

    .thank-you-page-rectangle {
        height: 950px;
        width: 100%;
    }

    .thank-you-page-quiz-video {
        width: 80%;
    }

    .thank-you-page-quiz-button {
        width: 250px;
    }
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
    .thank-you-page-background-lines {
        display: none;
    }
}