.landing-page-testimony-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 540px;
    height: 380px;
    background-color: #626262;
    border-radius: 10px;
}

.landing-page-testimony-card-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.landing-page-testimony-card-name-p {
    font-family: 'Poppins';
    font-size: 26px;
    font-weight: 800;
    line-height: 20px;
    margin: 10px;
    color: #ffffff;
}

.landing-page-testimony-card-occupation-p {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    color: #ffffff;
}

.landing-page-testimony-video-container {
    width: 400px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-page-testimony-video-placeholder {
    width: 100%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    background-color: #000; /* Placeholder background */
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-page-testimony-video {
    width: 100%;
    height: 100%;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .landing-page-testimony-video-container {
        width: 80%;
    }
    
    .landing-page-testimony-card {
        width: 80%;
        border-radius: 30px;
    }

    .landing-page-testimony-card-text-container {
        width: 100%;
    }

    .landing-page-testimony-card-occupation-p {
        text-align: center;
        font-size: 16px;
    }
}