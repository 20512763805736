.bootcamp-screen{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header */
.bootcamp-header-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -90px 0 -80px 0;
    padding: 0;
}

.bootcamp-svg-header{
    width: 1111px;
    height: 216px;
    margin-bottom: 0;
    padding-bottom: 0;
    z-index: -1;
}

.bootcamp-svg-header-background{
    width: 100vw;
    margin-top: -200px;
}

.bootcamp-header-div img{
    width: 164px;
    margin: 0 0 21px 0;
}

.bootcamp-header-div svg{
    margin: 0 0 71px 0;
}

@media screen and (max-width: 700px) {
    .bootcamp-header-div{
        margin: 10px 0 -54px 0;
        overflow: hidden;
    }

    .bootcamp-header-div img{
        width: 100px;
        margin: 0 0 0px 0;
        display: none;
    }
    .bootcamp-svg-header{
        width: 140%;
    }
    .bootcamp-svg-header-background{
        display: none;
    }
    .bootcamp-header-div svg{
        margin: 0;
    }
}

/* Purple Form */
.bootcamp-form-div{
    width: 100%;
    height: 100%;
    min-height: 400px;
    background-color: #9847FF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
}

.bootcamp-form-div-left{
    width: 30%;
    padding-right: 16px;
}

.bootcamp-form-div-left h1{
    color: #FFF;
    text-align: right;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.bootcamp-form-div-right{
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-left: 16px;
}

.label-bootcamp {
    display: block;
    margin-bottom: 2px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    padding-top: 7px;
  }

.bootcamp-first-name {
    width: 368px;
    height: 47px;
    padding-left: 40px;
    background: url("../../images/firstNameIcon.svg") no-repeat scroll 9px 10px;
    background-size: 20px;
    background-color: #393939;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.bootcamp-first-name:hover {
    background-color: #282827;
    transition-duration: 20ms;
}

.bootcamp-first-name:focus {
    background-color: #282827;
}

.bootcamp-email {
    width: 368px;
    height: 47px;
    padding-left: 40px;
    background: url("../../images/emailIcon.svg") no-repeat scroll 8px 12px;
    background-size: 22px;
    background-color: #393939;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.bootcamp-email:hover {
    background-color: #282827;
    transition-duration: 20ms;
}

.bootcamp-email:focus {
    background-color: #282827;
}

.bootcamp-first-name::placeholder,
.bootcamp-email::placeholder {
    color: #ffffff;
    font-size: 12px;
}

.bootcamp-button {
    width: 368px;
    height: 35px;
    background-color: #FFF;
    border-radius: 12px;
    border-color: transparent;
    font-size: 16px;
    font-family: 'Poppins';
    color: #000;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bootcamp-button-div {
    padding-top: 26px;
}

.bootcamp-button:hover {
    background-color: #EBB400;
    cursor: pointer;
}

.message-bootcamp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.message-bootcamp p{
    color: white;
}

@media screen and (max-width: 700px) {
    .bootcamp-form-div{
        padding-top: 20px;
        height: 100%;
        flex-direction: column;
    }
    
    .bootcamp-form-div-left{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
    }
    
    .bootcamp-form-div-left h1{
        text-align: center;
        font-size: 40px;
    }
    
    .bootcamp-form-div-right{
        width: 100%;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-first-name {
        width: 80%;
    }
    
    .bootcamp-email {
        width: 80%;
    }
    
    .bootcamp-button {
        width: 80%;
    }

    .bootcamp-button-div {
        width: 100%;
        padding: 26px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* Black div */
.bootcamp-black-div{
    width: 100%;
    height: 100%;
    min-height: 800px;
    background-color: black;
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bootcamp-black-div p{
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 46px;
    width: 70%;
}

.bootcamp-black-div p strong{
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bootcamp-price-row{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-bottom: 44px;
}

.bootcamp-price-row-left{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 25px;
}

.bootcamp-price-row-right{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-left: 1px solid white;
    padding-top: 0;
    margin-top: 0;
}

.bootcamp-price-row-right h1{
    color: #FFF;
    text-align: center;
    text-shadow: -3px -5px 0px #000;
    font-family: Poppins;
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bootcamp-price-row-right h1 span{
    color: #FFC300;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bootcamp-price-row-left h2{
    color: #FFC300;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.bootcamp-price-row-left span{
    color: #AEFF47;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.bootcamp-p-right{
    margin-left: 199px;
    text-align: right;
}

@media screen and (max-width: 700px) {
    .bootcamp-black-div{
        width: 100%;
        height: 100%;
        background-color: black;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-black-div p{
        color: #FFF;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 46px;
        width: 100%;
    }
    
    .bootcamp-black-div p strong{
        font-size: 20px;
    }
    
    .bootcamp-price-row{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 44px;
    }
    
    .bootcamp-price-row-left{
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding-right: 0px;
    }
    
    .bootcamp-price-row-right{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 0px solid white;
    }
    
    .bootcamp-price-row-right h1{
        font-size: 40px;
        text-align: center;
    }
    
    .bootcamp-price-row-right h1 span{
        font-size: 20px;
        text-align: center;
    }
    
    .bootcamp-price-row-left h2{
        font-size: 26px;
        text-align: center;
    }
    
    .bootcamp-price-row-left span{
        font-size: 10px;
        text-align: center;
    }

    .bootcamp-p-right{
        margin-left: 0px;
        text-align: right;
    }
}

/* Bootcamp Benefits - White Cards */
.bootcamp-benefits-section{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
}

.bootcamp-benefits-card{
    background-color: white;
    width: calc(100% / 4);
    padding: 60px 29px 0 29px;
    fill: #FFF;
    filter: drop-shadow(-5px 4px 25px rgba(0, 0, 0, 0.10));
    position: relative;
}

.bootcamp-benefits-card h5{
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.bootcamp-benefits-card h6{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.bootcamp-benefits-card p{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.card-benefits-one::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: -50px;
    left: 0;
    z-index: -1;
    background-color: white; /* Your color */
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
}

.card-benefits-two::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: -55px;
    left: 0;
    z-index: -1;
    background-color: white; /* Your color */
    clip-path: polygon(100% 10%, 0 0, 0 90%, 100% 100%);
}

.card-benefits-three::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: -45px;
    left: 0;
    z-index: -1;
    background-color: white; /* Your color */
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
}

.card-benefits-four::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: -55px;
    left: 0;
    z-index: -1;
    background-color: white; /* Your color */
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
}

@media screen and (max-width: 700px) {
    .bootcamp-benefits-section{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .bootcamp-benefits-card{
        background-color: white;
        width: 100%;
        padding: 60px 29px 0 29px;
        fill: #FFF;
        filter: drop-shadow(-5px 4px 25px rgba(0, 0, 0, 0.10));
        position: relative;
    }
    
    .bootcamp-benefits-card h5{
        color: #000;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    
    .bootcamp-benefits-card h6{
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .bootcamp-benefits-card p{
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    
    .card-benefits-one::before, .card-benefits-two::before, .card-benefits-three::before, .card-benefits-four::before{
        display: none;
    }
    
}


/* Video Section */
.bootcamp-video-div{
    padding-top: 100px;
    width: 100%;
    height: 646px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFC300;
}

.bootcamp-video-div h1{
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.bootcamp-video-div h1 span{
    color: #9847FF;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bootcamp-video-div h6{
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bootcamp-video-container{
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bootcamp-video-placeholder {
    width: 679px;
    height: 383px;
    max-width: 40%;
    max-height: 70%;
    background-color: #000; /* Placeholder background */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bootcamp-video {
    width: 679px;
    height: 383px;
    max-width: 40%;
    max-height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 700px) {
    .bootcamp-video-div{
        padding-top: 50px;
        width: 100%;
        height: 646px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #FFC300;
    }
    
    .bootcamp-video-div h1{
        color: #000;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .bootcamp-video-div h1 span{
        color: #9847FF;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .bootcamp-video-div h6{
        color: #000;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        width: 90%;
    }
    
    .bootcamp-video-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .bootcamp-video-placeholder {
        width: 90%;
        height: 250px;
        max-width: 90%;
        max-height: 70%;
        background-color: #000; /* Placeholder background */
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .bootcamp-video {
        width: 90%;
        height: 250px;
        max-width: 90%;
        max-height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* Syllabus Section */
.bootcamp-syllabus-div{
    width: 100%;
    height: 542px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.bootcamp-syllabus-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bootcamp-syllabus-header h1{
    color: #FFF;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 100% */
}

.bootcamp-syllabus-header h1 strong{
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
}

.bootcamp-syllabus-div p{
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 166.667% */
    width: 80%;
}

.bootcamp-syllabus-btn{
    width: 400px;
    height: 86px;
    border-radius: 15px;
    background-color: white;
    border-bottom: 1px solid white;
    z-index: 1;
}

.bootcamp-syllabus-btn a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
    position: relative;
    border-radius: 15px;
    background-color: #FFC300;
    border: 1px solid #FFC300;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 138.889% */
    text-transform: uppercase;
    z-index: 2;
}

.bootcamp-syllabus-btn a:hover{
    background-color: rgba(255, 202, 68, 1);
    border: 1px solid rgba(255, 202, 68, 1);
}

@media screen and (max-width: 700px) {
    .bootcamp-syllabus-div{
        padding: 20px 0;
        width: 100%;
        height: 100%;
    }
    
    .bootcamp-syllabus-header h1{
        font-size: 40px;
    }
    
    .bootcamp-syllabus-header h1 strong{
        font-size: 30px;
    }
    
    .bootcamp-syllabus-div p{
        font-size: 24px;
    }
    
    .bootcamp-syllabus-btn{
        height: 50px;
        width: 70%;
    }
    
    .bootcamp-syllabus-btn a{
        font-size: 24px;
    }
    
    .bootcamp-syllabus-btn a:hover{
        background-color: rgba(255, 202, 68, 1);
        border: 1px solid rgba(255, 202, 68, 1);
    }
}

/* Curriculum */
.bootcamp-curriculum-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bootcamp-curriculum-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bootcamp-curriculum-header h1{
    padding-left: 10px;
    color: #000;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 100% */
}

.bootcamp-curriculum-header h1 span{
    padding-left: 10px;
    color: #000;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
}

.bootcamp-curriculum-section h6{
    padding: 33px 0 100px 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 166.667% */
}

.bootcamp-curriculum-parts-left h1, .bootcamp-curriculum-middle-right h1{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 147.059% */
}

.bootcamp-curriculum-parts-left h1 span, .bootcamp-curriculum-middle-right h1 span{
    color: #9847FF;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
}   

.bootcamp-curriculum-parts-left p, .bootcamp-curriculum-middle-right p{
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 166.667% */
}

.bootcamp-curriculum-parts, .bootcamp-curriculum-middle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 80px;
}

.bootcamp-curriculum-parts-left, .bootcamp-curriculum-middle-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.bootcamp-curriculum-parts-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.bootcamp-curriculum-parts-right svg, .bootcamp-curriculum-middle-left svg{
    width: 60%;
}


.bootcamp-curriculum-parts-left{
    padding-left: 110px;
}

.bootcamp-curriculum-middle-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 110px;
}

.bootcamp-check-div{
    display: flex;
    flex-direction: row;
}

.bootcamp-check-div p{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;   
    padding-left: 18px;
}

@media screen and (max-width: 700px) {
    
    .bootcamp-curriculum-header{
        flex-direction: column;
    }
    
    .bootcamp-curriculum-header h1{
        padding-left: 0px;
        font-size: 40px;
        text-align: center;
    }
    
    .bootcamp-curriculum-header h1 span{
        padding-left: 0px;
        font-size: 30px;
        text-align: center;
    }
    
    .bootcamp-curriculum-section h6{
        padding: 33px 0;
        font-size: 24px;
    }
    
    .bootcamp-curriculum-parts-left h1, .bootcamp-curriculum-middle-right h1{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 147.059% */
    }
    
    .bootcamp-curriculum-parts-left h1 span, .bootcamp-curriculum-middle-right h1 span{
        color: #9847FF;
        font-family: Poppins;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
    }   
    
    .bootcamp-curriculum-parts-left p, .bootcamp-curriculum-middle-right p{
        color: #000;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px; /* 166.667% */
    }
    
    .bootcamp-curriculum-parts, .bootcamp-curriculum-middle {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .bootcamp-curriculum-parts-left, .bootcamp-curriculum-middle-left{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-curriculum-parts-right{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-curriculum-parts-right svg, .bootcamp-curriculum-middle-left svg{
        width: 100%;
        display: none;
    }
    
    
    .bootcamp-curriculum-parts-left{
        padding-left: 0px;
    }
    
    .bootcamp-curriculum-middle-right{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
    }

    .bootcamp-curriculum-middle-left {
        display: none;
    }
    
    .bootcamp-check-div{
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .bootcamp-check-div p{
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;   
        padding-left: 18px;
    }
}

/* Summary */
.bootcamp-summary-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 800px;
    background-color: #000;
    justify-content: center;
    align-items: center;
}

.bootcamp-summary-div-texts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
}

.bootcamp-summary-div-texts h5{
    color: #FFF;
    text-align: left;
    font-family: "Roboto Flex";
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal; 
}

.bootcamp-summary-div-texts h6{
    color: #FFF;
    text-align: left;
    font-family: "Roboto Flex";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bootcamp-summary-div{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 40%;
    margin: 30px 0;
}

.bootcamp-image-summary{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .bootcamp-summary-section{
        width: 100%;
        height: 100%;
    }
    
    .bootcamp-summary-div-texts{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .bootcamp-summary-div-texts h5{
        color: #FFF;
        text-align: center;
        font-family: "Roboto Flex";
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: normal; 
    }
    
    .bootcamp-summary-div-texts h6{
        color: #FFF;
        text-align: center;
        font-family: "Roboto Flex";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .bootcamp-summary-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 70%;
        margin: 30px 0;
    }
    
    .bootcamp-image-summary{
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
}

/* Testimonials */
.bootcamp-testimonial-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 800px;
    justify-content: center;
    align-items: center;
    background-color: #FFC300;
    padding: 30px 0;
}

.bootcamp-testimonial-header{
    display: flex;
    flex-direction: row;
}

.bootcamp-testimonial-header h1{
    color: #000;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 100% */
}

.bootcamp-testimonial-header h1 span{
    color: #000;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
}

.bootcamp-testimonial-section-p{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 166.667% */
    margin: 40px 0;
}

.bootcamp-testimonial-div{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.bootcamp-testimony-card-container {
    width: 377px;
    height: 311px;
    margin: 0 10px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.10);
}

.bootcamp-testimony-card-name {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 141.75%; /* 28.35px */
}

.bootcamp-testimony-card-occupation {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 141.75%; /* 22.68px */
    padding-bottom: 13px;
}

.bootcamp-testimony-card-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bootcamp-testimony-card-video {
    height: 217px;
    width: 348px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bootcamp-testimony-cards-video-placeholder {
    width: 348px; /* Adjust as needed */
    height: 217px; /* Adjust as needed */
    background-color: #000; /* Placeholder background */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .bootcamp-testimony-card-container {
        width: 90%;
        height: 250px;
        margin-bottom: 30px;
    }

    .bootcamp-testimony-card-name {
        font-size: 16px;
    }
    
    .bootcamp-testimony-card-occupation {
        font-size: 14px;
        margin-bottom: 0px;
        line-height: 8px;
    }
    
    .bootcamp-testimony-card-video {
        height: 100%;
        width: 90%;
    }
    
    .bootcamp-testimonial-header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-testimonial-header h1{
        font-size: 40px;
    }
    
    .bootcamp-testimonial-header h1 span{
        font-size: 30px;
    }
    
    .bootcamp-testimonial-section-p{
        font-size: 22px;
        line-height: 40px; /* 166.667% */
        margin: 40px 10px;
    }
    
    .bootcamp-testimonial-div{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-testimony-card-video-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .bootcamp-testimony-cards-video-placeholder {
        width: 90%; /* Adjust as needed */
        height: 100%; /* Adjust as needed */
        background-color: #000; /* Placeholder background */
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* FAQ */
.bootcamp-faq-section{
    width: 100%;
    height: 1580px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #393939;
}

.bootcamp-faq-section h1{
    color: #FFF;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 46px;
}

.bootcamp-faq-div{
    width: 90%;
    display: flex;
    flex-direction: row;
}

.bootcamp-faq-div-left, .bootcamp-faq-div-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bootcamp-faq-card{
    width: 467px;
    height: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 10px 4px 15px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 27px 0;
    margin: 20px 0;
}

.bootcamp-faq-card h6{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px; /* 138.889% */
    width: 80%;
}

.bootcamp-faq-card p{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 178.571% */
    width: 80%;
}

@media screen and (max-width: 700px) {
    .bootcamp-faq-section{
        width: 100%;
        height: 100%;
        padding: 20px 0;
    }
    
    .bootcamp-faq-section h1{
        font-size: 34px;
        text-align: center;
        padding: 26px 0;
    }
    
    .bootcamp-faq-div{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bootcamp-faq-div-left, .bootcamp-faq-div-right{
        width: 100%;
    }
    
    .bootcamp-faq-card{
        width: 90%;
    }
}

/* Countdown Timer */
.bootcamp-countdown-timer-div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 4;
    background-color: #9847FF;
    padding-top: 20px;
}

.bootcamp-countdown-timer-square{
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bootcamp-countdown-timer-square h2{
    font-family: Poppins;
    font-size: 60px;
    font-weight: 700;
    line-height: 51.2px;
    text-align: center;
    color: white;
}

.bootcamp-countdown-timer-square p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 31.2px;
    text-align: center;
    color: white;
}

@media screen and (max-width: 700px) {
    .bootcamp-countdown-timer-div{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 4;
    }
    
    .bootcamp-countdown-timer-square{
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .bootcamp-countdown-timer-square h2{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 31.2px;
        text-align: center;
        color: white;
    }
    
    .bootcamp-countdown-timer-square p{
        font-family: Poppins;
        font-size: 10px;
        font-weight: 300;
        line-height: 21.2px;
        text-align: center;
        color: white;
    }
}

/* Bootcamp Payment */
.bootcamp-form-div-payment{
    width: 100%;
    height: 100%;
    min-height: 300px;
    background-color: #9847FF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
}

.bootcamp-form-div-payment-price, .bootcamp-form-div-payment-spots{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.bootcamp-form-div-payment-price svg{
    width:224px;
    height:113px;
}

.bootcamp-form-div-payment-price h1{
    font-family: Poppins;
    font-size: 55px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: white;
}

.bootcamp-form-div-payment-price h2{
    font-family: Poppins;
    font-size: 35px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: white;
}

.bootcamp-form-div-payment-price h1 span{
    font-family: Poppins;
    font-size: 45px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    color: #FFC300;
}

.bootcamp-form-div-payment-main{
    width: 50%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bootcamp-form-div-payment-btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.bootcamp-form-div-payment-main h1{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 40.2px;
    letter-spacing: 0.41em;
    color: white;
    margin-bottom: 20px;
}

.bootcamp-form-div-payment-main a{
    width: 80%;
}

.bootcamp-payment-button-book{
    width: 95%;
    height: 90px;
    gap: 0px;
    border-radius: 15px;
    opacity: 1;
    background-color: #FFC300;
    border: 1px solid #FFC300;
    color: black;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 700;
    line-height: 56px;
    text-align: center;
    box-shadow: 5px 8px 0px black;
    z-index: 4;
}

.bootcamp-payment-button-book:hover{
    background-color: #ffd651;
}

.paragraph-about-installments{
    padding-top: 20px;
    color: white;
}

.bootcamp-spots-card-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.bootcamp-spots-card{
    width: 77px;
    height: 106px;
    top: 586px;
    left: 1021.08px;
    gap: 0px;
    border-radius: 5px 0px 0px 0px;
    opacity: 1;
    background-color: white;
    box-shadow: -6px 6px 0px rgba(255, 195, 0, 1);
    margin: 0 5px 5px 5px;
    font-family: Poppins;
    font-size: 70px;
    font-weight: 700;
    line-height: 105px;
    text-align: center;
    color: #000;
    z-index: 4;
}

.bootcamp-form-div-payment-spots h1{
    font-family: Poppins;
    font-size: 44px;
    font-weight: 700;
    line-height: 66px;
    text-align: right;
    color: white;
    transform: rotate(1.75deg);
}

@media screen and (max-width: 700px) {
    .bootcamp-form-div-payment{
        width: 100%;
        flex-direction: column;
    }

    .bootcamp-form-div-payment-price svg{
        width:124px;
        height:63px;
    }

    .bootcamp-form-div-payment-price{
        padding-top: 20px;
    }

    .bootcamp-form-div-payment-price, .bootcamp-form-div-payment-spots{
        width: 70%;
    }
    
    .bootcamp-form-div-payment-main{
        width: 80%;
        height: 100%;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
    }
    
    .bootcamp-form-div-payment-main h1{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 40.2px;
        letter-spacing: 0.41em;
        color: white;
        margin-bottom: 20px;
        text-align: center;
    }
    
    .bootcamp-payment-button-book{
        width: 100%;
        height: 66px;
        gap: 0px;
        border-radius: 15px;
        opacity: 1;
        font-family: Poppins;
        font-size: 23px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .paragraph-about-installments{
        padding-top: 10px;
        color: white;
        font-size: 14px;
        text-align: center;
    }

    .bootcamp-form-div-payment-btns{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .bootcamp-form-div-payment-main a{
        width: 100%;
    }
    
    .bootcamp-spots-card-row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .bootcamp-spots-card{
        width: 67px;
        height: 96px;
        top: 586px;
        left: 1021.08px;
        gap: 0px;
        border-radius: 5px 0px 0px 0px;
        opacity: 1;
        background-color: white;
        box-shadow: -6px 6px 0px rgba(255, 195, 0, 1);
        margin: 0 5px 5px 5px;
        font-family: Poppins;
        font-size: 60px;
        font-weight: 700;
        line-height: 105px;
        text-align: center;
        color: #000;
    }
    
    .bootcamp-form-div-payment-spots h1{
        font-family: Poppins;
        font-size: 30px;
        font-weight: 700;
        line-height: 66px;
        text-align: right;
        color: white;
        transform: rotate(1.75deg);
    }

    .bootcamp-form-div-payment-price h1{
        font-size: 35px;
        line-height: 35px;
    }
    
    .bootcamp-form-div-payment-price h2{
        font-size: 20px;
        line-height: 20px;
    }
    
    .bootcamp-form-div-payment-price h1 span{
        font-family: Poppins;
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color: #FFC300;
    }
}

.bootcamp-price-row-meeting{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
}

.bootcamp-price-row-meeting-left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.bootcamp-price-row-meeting-right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 100px;
    z-index: 4;
}

.bootcamp-price-row-meeting-left h2{
    font-family: Poppins;
    font-size: 34px;
    font-weight: 700;
    line-height: 54px;
    text-align: right;
    color: white;    
}

.bootcamp-price-row-meeting-left p{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
    text-align: right;
    color: white;    
}

.bootcamp-price-row-meeting-button{
    width: 250px;
    height: 100px;
    top: 1101px;
    left: 755px;
    gap: 0px;
    border-radius: 10px;
    opacity: 1;
    background-color: #FFC300;
    border: 1px solid #FFC300;
    box-shadow: 3px 4px 0px white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bootcamp-price-row-meeting-button:hover{
    background-color: rgba(255, 202, 68, 1);
}

.bootcamp-price-row-meeting-button div{
    display: flex;
    flex-direction: column;
}

.bootcamp-price-row-meeting-button div p{
    color: #626262;   
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left; 
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 700px) {
    .bootcamp-price-row-meeting{
        flex-direction: column;
        width: 100%;
        margin-bottom: 60px;
    }
    
    .bootcamp-price-row-meeting-left{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-price-row-meeting-right{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }
    
    .bootcamp-price-row-meeting-left h2{
        font-family: Poppins;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        color: white;    
    }
    
    .bootcamp-price-row-meeting-left p{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 300;
        line-height: 36px;
        text-align: center;
        color: white;    
    }
    
    .bootcamp-price-row-meeting-right-button{
        width: 150px;
        height: 80px;
        top: 1101px;
        left: 755px;
        gap: 0px;
        border-radius: 10px;
        opacity: 1;
        background-color: #FFC300;
        box-shadow: 3px 4px 0px white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .bootcamp-price-row-meeting-button:hover{
        background-color: rgba(255, 202, 68, 1);
    }
    
    .bootcamp-price-row-meeting-button div{
        display: flex;
        flex-direction: column;
    }
    
    .bootcamp-price-row-meeting-button div p{
        color: #626262;   
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left; 
        padding: 0;
        margin: 0;
    }
}