.landing-page-screen {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.landing-page-gray-background-top {
    width: 100%;
    height: 559px;
    background-color: #626262;
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-direction: row;
}

.landing-page-background-lines {
    width: 100%;
    height: 559px;
    border-radius: 0 0 20px 20px;
    opacity: 15%;
    position: absolute;
}

.landing-page-top-session-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 559px;
}

.landing-page-top-session-right {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 559px;
}

.landing-page-white-background {
    height: 1050px;
    width: 100%;
}

.landing-page-what-costumers-say-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0 0px 0;
}

.landing-page-what-costumers-say {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 26px;
    color: #9847FF;
}

.landing-page-testimony-cards-container {
    height: 410px;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.landing-page-testimony-cards-container-2 {
    height: 410px;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.testimony-cards-container {
    height: 450px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.landing-page-top-left-card {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0 30px 30px 0;
    width: 50%;
}

.landing-page-top-right-card {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 0 30px 30px 0;
    width: 50%;
}

.landing-page-bottom-left-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 0 30px 30px 0;
    width: 50%;
}


.landing-page-bottom-right-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 30px 30px 0;
    width: 50%;
}


/* Mobile Version */
@media screen and (max-width: 700px) {
    .landing-page-screen {
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .landing-page-background-lines {
        display: none;
    }

    .landing-page-gray-background-top {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .landing-page-top-session-left {
        justify-content: center;
        width: 100%;
    }

    .landing-page-top-session-right {
        align-items: center;
        width: 100%;
        flex-direction: column;
    }

    .landing-page-white-background {
        height: 1600px;
    }

    .landing-page-testimony-cards-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 800px;
    }

    .landing-page-testimony-cards-container-2 {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 400px;
    }

    .landing-page-top-left-card, .landing-page-top-right-card, .landing-page-bottom-left-card, .landing-page-bottom-right-card {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }

    .landing-page-what-costumers-say-container {
        margin: 60px 0 0 0;
    }
    
}