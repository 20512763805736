.proficiency-test-screen{
    background-color: white;
    height: 100vh;
}

.container-quiz-intro{
    display: flex;
    flex-direction: column;
    margin: 0px 50px;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    width: 70%;
}

.container-quiz-level{
    margin: 30px 30px;
    justify-content: left;
    align-items: left;
    padding-bottom: 0px;
    text-align: left;
}

.container-logo-quiz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-quiz-level-paragraph{
    margin: 25px 25px;
    justify-content: left;
    align-items: left;
    text-align: left;
}

.card-for-logo{
    width: 578px;
    height: 112px;
    background: #626262;
    opacity: 70%;
    border-radius: 25px;
    margin: 43px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.logo-quiz-finished{
    width: 432px;
    height: 231px;
}

.card-for-logo-quiz{
    width: 578px;
    height: 112px;
    background: #626262;
    opacity: 70%;
    border-radius: 25px;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.quiz-logo{
    width: 348px;
}

.card-for-logo img{
    width: 400px;
    height: 130px;
}

.container-text-intro{
    display: flex;
    flex-direction: column;
}

.container-text-intro-quiz{
    display: flex;
    flex-direction: row;
    height: 350px;
    max-height: 350px;
}

.intro-quiz-left{
    width: 500px;
    height: 100%;
    max-height: 350px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    padding-right: 33px;
}

.intro-quiz-left p:nth-child(2) {
    margin-bottom: 20px;
}

.intro-quiz-left p {
    font-size: 24px !important;
}

.intro-quiz-left p:nth-child(1),
.intro-quiz-left p:nth-child(2) {
    font-size: 20px !important;
}

.line-divider {
    height: 100%;
    border-right: 1px solid #000000;
}

.intro-quiz-right{
    width: 500px;
    height: 100%;
    max-height: 350px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left: 33px;
}

.intro-quiz-right p:nth-child(3),
.intro-quiz-right p:nth-child(6) {
    margin-bottom: 20px;
}

.container-quiz-intro h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #626262;
}

.container-quiz-intro p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #626262;
    margin: 3px;
}

.container-quiz-intro p strong{
    margin: 0 2px;
}

.container-quiz-intro h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #626262;
}

.start-test{
    margin-top: 36px;
    width: 384px;
    height: 49px;
    background: #9847FF;
    border: 1px solid #9847FF;
    border-radius: 12px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

/* Question */
.proficiency-test-question-screen{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.proficiency-test-question-screen h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 100%;
    text-align: center;
    color: #000000;
    margin: 15px 0 5px 0;
}

.estimated-time-quiz{
    width: 80%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.proficiency-test-question-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 948px;
    height: 597px;
    background: #626262;
    border-radius: 25px;
}

.proficiency-test-exercise-counter{
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    padding-top: 10px;
    margin: 0;
}

.proficiency-test-question{
    height: 520px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}

.quiz-question-card-left{
    width:  50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.proficiency-test-question-card-right{
    width:  50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0 0 0 20px;
    margin: 0px;
    height: 100%;
}

.proficiency-test-question-image{
    max-width: 90%;
    min-height: 50%;
    max-height: 90%;
}

.proficiency-test-question-left-h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #FDB500;
}

.proficiency-test-question-left-h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #ffffff;
    padding-bottom: 10px;
}

.proficiency-test-question-left-p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
}

.proficiency-test-question-line{
    height: 94%;
    border: 0.5px solid #E8F2E5;
}

.div-quiz-options-alone{
    display: flex;
    flex-direction: row;
    align-items: center; /* Align items to the center */
    justify-content: center;
    background-color: #1E1E1E;
    border-radius: 12px;
    margin: 5px;
    cursor: pointer;
}

.div-quiz-options-alone:hover {
    background-color: #FDB500; 
    cursor: pointer;
}

.div-quiz-alternatives {
    width: 100px;
    height: 45px;
    border-color: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.div-label {
    color: #FFFFFF;
    width: 268px;
    font-weight: 600;
    cursor: pointer;
    padding-right: 3px;
}

input[type="radio"] {
    transform: scale(0.6); /* Adjust the scale factor as needed */
    margin: 2.5px 0;
    cursor: pointer;
}

.next-test-button{
    width: 368px;
    height: 35px;
    background-color: #9847FF;
    border-radius: 12px;
    border-color: transparent;
    font-size: 16px;
    font-family: 'Roboto Flex';
    color: #FFFFFF;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.next-test-button:hover {
    background-color: rgb(156, 80, 255);
}

.next-test-button:disabled, .last-page-quiz-survey-button:disabled{
    background-color: #ccc; /* Gray background */
    color: #666; /* Darker text color */
    cursor: not-allowed; 
}

.next-test{
    margin-top: 10px;
    width: 50%;
    height: 42px;
    background: #9847FF;
    border: 1px solid #9847FF;
    border-radius: 12px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
}

.proficiency-test-timer{
    background: #9847FF;
    border-radius: 12px;
    width: 100%;
    height: 42px;
    margin-bottom: 26px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.feedback-proficiency{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 15px;
}

.div-btn-msg-proficiency-question{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 23px;
    width: 380px;
}

.error-message-quiz{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.error-message-quiz p{
    color: white;
}

/* Results */
.container-text-result{
    margin-top: 26px;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.container-quiz-intro h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
}

.last-page-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 948px;
    height: 597px;
    background: #626262;
    border-radius: 25px;
    z-index: 0;
}

.last-page-quiz-right{
    width: 50%;
    height: 445px;
    max-height: 597px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 36px;
}

.last-page-quiz-font-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #FDB500;
}

.last-page-quiz-font-body {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: #ffffff;
}

.label-last-page-quiz-survey {
    display: block;
    margin-bottom: 2px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    padding-top: 7px;
  }

.last-page-quiz-survey-first-name {
    width: 368px;
    height: 47px;
    padding-left: 40px;
    background: url("../../images/firstNameIcon.svg") no-repeat scroll 9px 10px;
    background-size: 20px;
    background-color: #AF8B31;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.last-page-quiz-survey-first-name:hover {
    background-color: #ebb428;
    transition-duration: 20ms;
}

.last-page-quiz-survey-first-name:focus {
    background-color: #ebb428;
}

.last-page-quiz-survey-email {
    width: 368px;
    height: 47px;
    padding-left: 40px;
    background: url("../../images/emailIcon.svg") no-repeat scroll 8px 12px;
    background-size: 22px;
    background-color: #AF8B31;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.last-page-quiz-survey-email:hover {
    background-color: #ebb428;
    transition-duration: 20ms;
}

.last-page-quiz-survey-email:focus {
    background-color: #ebb428;
}

.checkbox {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-top: 14px;
    padding-bottom: 26px;
}

.checkbox input[type="checkbox"] {
    appearance: none; /* Remove default styles */
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #ffffff;
    opacity: 50%;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
  }

.checkbox input[type="checkbox"]:checked {
    background-color: #FDB500;
    border-color: #ffffff;
    opacity: 100%;
}

.label-last-page-quiz-survey-checkbox {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    padding-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

.last-page-quiz-survey-first-name::placeholder,
.last-page-quiz-survey-email::placeholder {
    color: #ffffff;
    font-size: 12px;
}

.last-page-quiz-survey-button {
    width: 368px;
    height: 35px;
    background-color: #9847FF;
    border-radius: 12px;
    border-color: transparent;
    font-size: 16px;
    font-family: 'Roboto Flex';
    color: #FFFFFF;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.survey-button-div {
    padding-top: 26px;
}

.last-page-quiz-survey-button:hover {
    background-color: rgb(156, 80, 255);
}

.last-page-quiz-left {
    width: 50%;
    height: 597px;
    max-height: 597px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 33px;
    position: relative;
}

.last-page-quiz-image {
    width: 105%;
    transform: rotate(-2deg);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s ease-out;
    transition: transform 0.5s ease-out;
}

.last-page-quiz-image:hover {
    width: 106%;
    transform: rotate(-3deg);
    transition-duration: 20ms;
}

.last-page-quiz-image-fill {
    width: 100%;
    position: absolute;
    display: flex;
    opacity: 20%;
    z-index: -1;
}

.container-text-result-left, .container-text-result-right{
    width: 45%;
}

.container-text-result-left p, .container-text-result-right p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #626262;
}

.container-text-result-left p{
    text-align: right;
}

.container-text-result-right p{
    text-align: left;
}

.proficiency-result-card-next-week{
    width: 314px;
    height: 77px;
    background: linear-gradient(309.82deg, #EBB400 -49.74%, #9847FF 92.05%);
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin: 18px 0;
}

.proficiency-result-card-next-week h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 15px;
    padding-top: 10px;
}

.proficiency-result-card-next-week p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    text-decoration-line: underline;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 15px;
}

.proficiency-result-score-div{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.proficiency-result-score-div-left{
    width: 30%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #626262;
}

.card-for-logo h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FDB500;
}

.proficiency-result-line{
    width: 0px;
    height: 120px;
    border: 3px solid #E8F2E5;
    margin-right: 22px;
}

.results-quiz-logo{
    width: 400px;
}

.results-card-for-logo-quiz{
    width: 578px;
    height: 112px;
    background: #8A8D89;
    opacity: 100%;
    border-radius: 25px;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.container-text-results-quiz{
    display: flex;
    flex-direction: row;
    height: 380px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.video-results-quiz{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quiz-video-placeholder{
    cursor: pointer;
    width: 80%;
}

.quiz-video{
    width: 80%;
    height: 100%;
}

.results-quiz-left{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    padding-right: 33px;
    white-space: nowrap;
}

.results-quiz-left p:nth-child(1) {
    font-size: 30px;
    color: #9847FF;
    line-height: 60px;
}

.results-quiz-left p:nth-child(4) {
    line-height: 60px;
}

.results-quiz-left p:nth-child(3) {
    font-size: 20px;
    color: #626262;
    font-weight: 600;
    text-align: right;
    line-height: 60px;
}

.results-quiz-left p:nth-child(5) {
    font-size: 20px;
    color: #626262;
    font-weight: 600;
    text-align: right;
}

.results-quiz-left p:nth-child(6) {
    line-height: 60px;
}

.results-quiz-right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left: 33px;
    color: #626262;
    font-size: 20px;
}

.btn-quiz-result-start-test {
    width: 300px;
    height: 40px;
    background: #9847FF;
    border: 1px solid #9847FF;
    border-radius: 12px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #FFFFFF;
}

.btn-quiz-result-start-test:hover{
    background-color: rgb(111, 0, 255);
}

.results-quiz-bottom-paragraph-week {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
}

.start-test-container{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .quiz-video-placeholder{
        cursor: pointer;
        width: 100%;
    }

    .container-text-results-quiz{
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .video-results-quiz{
        width: 100%;
        height: 200px;
    }

    .results-quiz-logo{
        width: 80%;
    }

    .results-card-for-logo-quiz{
        width: 100%;
    }

    .proficiency-test-screen{
        background-color: white;
        height: 110vh;
    }

    .card-for-logo{
        width: 70%;
        height: 200px;
    }

    .card-for-logo-quiz{
        width: 100%;
    }

    .card-for-logo img{
        width: 100%;
        height: 45%;
    }

    .container-quiz-intro h6{
        font-size: 22px;
        line-height: 26px;
        text-align: center;
    }
    
    .container-quiz-intro p{
        font-size: 18px;
        line-height: 28px;
        margin: 3px;
        text-align: center;
    }
    
    .start-test{
        width: 90%;
        height: 59px;
        border-radius: 12px;
        font-size: 28px;
    }

    .container-quiz-intro p strong{
        margin: 0 4px;
    }

    .container-text-intro-quiz {
        flex-direction: column;
        align-items: center;
    }

    .card-for-logo-quiz{
        margin: 10px 0;
    }

    .intro-quiz-left,
    .intro-quiz-right,
    .line-divider {
        width: 100%;
        text-align: center;
        margin: 10px 0;
        border: none;
    }

    .intro-quiz-right{
        width: 500px;
        height: 100%;
        max-height: 500px;
        align-items: center;
        padding-left: 0px;
    }

    .intro-quiz-left{
        width: 500px;
        height: 100%;
        max-height: 500px;
        align-items: center;
        padding-right: 0px;
    }


    .line-divider {
        border-bottom: 1px solid #000000;
        width: 70%;
    }

    .card-for-logo-quiz {
        padding: 10px; /* Add padding to the card on small screens */
    }

    .quiz-logo {
        width: 100%; /* Full width for the logo on small screens */
    }

    .intro-quiz-right p {
        font-size: 16px;
    }

    .intro-quiz-left p {
        font-size: 20px !important; 
    }

    .start-test-container {
        width: 100%;
        flex-direction: column;
    }

    .last-page-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 400px;
        height: 850px;
        background: #626262;
        border-radius: 25px;
        z-index: 0;
    }

    .last-page-quiz-left {
        width: 100%;
        height: 445px;
        max-height: 897px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }

    .last-page-quiz-right{
        width: 100%;
        height: 445px;
        max-height: 897px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-left: 0px;
    }

    .proficiency-test-question-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 385px;
        height: 960px;
        background: #8A8D89;
        border-radius: 25px;
        transition: background 0.4s ease-out;
    }

    .proficiency-test-question-screen{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        height: 960px;
    }

    .proficiency-test-question{
        height: 960px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .proficiency-test-question-card-left{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .proficiency-test-question-card-right{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 0;
    }

    .proficiency-test-question-image{
        width: 70%;
    }

    .proficiency-test-question-line{
        height: 0%;
        width: 80%;
        display: flex;
        justify-content: center;
        margin: 35px;
        border: 0.5px solid #E8F2E5;
    }

    .proficiency-test-exercise-counter{
        padding-top: 0px;
        margin: 0 0 0 0;
        padding-top: 25px;
    }
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
    .card-for-logo{
        width: 858px;
        height: 217px;
    }

    .container-quiz-intro h6{
        font-size: 34px;
        line-height: 48px;
    }
    
    .container-quiz-intro p{
        font-size: 28px;
        line-height: 42px;
        margin: 3px;
    }
    
    .start-test{
        width: 484px;
        height: 69px;
        border-radius: 12px;
        font-size: 34px;
    }

    .proficiency-test-question-screen h1{
        font-size: 38px;
        line-height: 45px;
        margin: 46px 0;
    }
    
    .proficiency-test-question-card{
        width: 1200px;
        height: 800px;
    }
    
    .proficiency-test-exercise-counter{
        height: 100px;
        font-size: 28px;
        line-height: 36px;
    }
    
    .proficiency-test-question{
        height: 800px;
    }
    
    .proficiency-test-question-left-h2{
        font-size: 30px;
        line-height: 48px;
    }
    
    .proficiency-test-question-left-h3{
        font-size: 28px;
        line-height: 50px;
    }
    
    .proficiency-test-question-left-p{
        font-size: 24px;
        line-height: 40px;
    }
    
    .next-test{
        height: 59px;
        border-radius: 12px;
        font-size: 28px;
    }
    
    .proficiency-test-timer{
        height: 59px;
        border-radius: 12px;
        font-size: 28px;
    }

    /* Results */
    .container-text-result-left p, .container-text-result-right p{
        font-size: 35px;
        line-height: 130%;
    }

    .proficiency-result-card-next-week{
        width: 414px;
        height: 97px;
        margin: 40px 0;
    }

    .proficiency-result-card-next-week h6{
        font-size: 30px;
        padding-left: 30px;
    }

    .proficiency-result-card-next-week p{
        font-size: 22px;
        padding-left: 30px;
    }

    .proficiency-result-score-div-left{
        font-size: 45px;
        line-height: 130%;
    }

    .card-for-logo h1{
        font-size: 60px;
    }

    .container-quiz-intro h1{
        font-size: 50px;
    }

    .intro-quiz-right p {
        font-size: 24px;
    }

    .intro-quiz-left p {
        font-size: 26px !important; 
    }

    .intro-quiz-left p:nth-child(1),
    .intro-quiz-left p:nth-child(2) {
        font-size: 26px !important;
    }

    .intro-quiz-right {
        max-height: 600px;
        height: 500px;
    }

    .intro-quiz-left {
        max-height: 600px;
        height: 500px;
    }

    .line-divider {
        height: 500px;
    }

    .start-test-container {
        margin-top: 145px; /* Adjust the margin as needed */
        text-align: center; /* Center the button */
        width: 100%;
    }
    
    .quiz-logo{
        width: 448px;
    }

    .card-for-logo-quiz{
        width: 700px;
        height: 130px;
    }

    .last-page-card{
        flex-direction: row;
        width: 1300px;
        height: 900px;
    }

    .last-page-quiz-font-title {
        font-size: 26px;
    }
    
    .last-page-quiz-font-body {
        font-size: 26px;
    }

    .label-last-page-quiz-survey {
        margin-bottom: 6px;
        font-size: 18px;
      }
    
    .last-page-quiz-survey-first-name {
        width: 500px;
        font-size: 20px;
    }

    .last-page-quiz-survey-email {
        width: 500px;
        font-size: 20px;
    }

    .last-page-quiz-survey-first-name::placeholder,
    .last-page-quiz-survey-email::placeholder {
        font-size: 18px;
    }

    .last-page-quiz-survey-button {
        width: 500px;
        height: 70px;
        font-size: 22px;
    }
}