/* Theory Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Add this to make sure the modal is in front of the content */
  }
  
  .modal {
    background-color: rgba(253, 181, 0, 1);
    padding: 20px;
    border-radius: 12px;
    max-width: 650px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Change the flex direction to column */
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box; /* Add this to include padding and border in the height calculation */
    /*overflow-y: auto;  Add this to enable scrolling if the content overflows the height */
    position: absolute; /* Add this to position the modal in the center of the overlay */
    left: 50%; /* Add this to position the modal horizontally in the center */
    transform: translateX(-50%); /* Add this to adjust the modal's position based on its width */
  }
  
  .modal-content{
    background-color: rgba(253, 181, 0, 1);
    border: 1px solid rgba(253, 181, 0, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  
  .close-btn-modal{
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: #3B432C;
    border: 1px solid #3B432C;
    color: white;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
  }
  
  .close-btn-modal:hover, .btn-modal:hover{
    background-color: black;
    border: black;
    color: #FFFFFF;
  }
  
  .close-btn-modal:active, .btn-modal:active{
    background-color: #B5A667;
    border: #B5A667;
    color: #FFFFFF;
  }
  
  .div-close-btn-modal{
    width: 100%;
    text-align: end;
  }
  
  .modal-title{
    width: 100%;
  }
  
  .modal-content h2{
    height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgba(152, 71, 255, 1);
    width: 100%;
  }
  
  .p-modal{
    width: 95%;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: rgba(98, 98, 98, 1);
  }
  
  .code-block-theory{
    background-color: rgba(59, 67, 44, 1);
    border-radius: 12px;
    border: rgba(59, 67, 44, 1);
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: white;
    white-space: pre-wrap;
    overflow-x: auto;
    padding: 7px;
    padding-bottom: 20px;
  }
  
  .inline-code-block-theory{
    background-color: rgba(59, 67, 44, 1);
    border-radius: 12px;
    border: rgba(59, 67, 44, 1);
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: white;
    white-space: pre-wrap;
    overflow-x: auto;
    padding: 7px;
    margin: 5px;
    padding: 8px;
  }
  
  .div-btn-modal{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .btn-modal-theory{
    width: 233px;
    height: 43px;
    background: rgba(152, 71, 255, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: rgba(152, 71, 255, 1);
    color: white;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .btn-modal-theory:hover{
    background: rgb(85, 4, 190);
    border: rgb(85, 4, 190);
  }

  .btn-modal-change-theory{
    background-color: rgba(59,67,44,1);
    border: rgba(59,67,44,1);
    width: 123px;
    height: 32px;
    border-radius: 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    margin-right: 10px;
  }

  .btn-modal-change-theory:hover{
    background-color: black;
  }
  