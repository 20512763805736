/* Import 'Poppins' font-family */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.screen-container{
  margin: 0px;
}

a, a:hover{
  color: 'white';
  text-decoration: none;
  background-color: transparent;
}

:root{
  --primary: #3B432C;
  --secondary: #559E98;
  --tertiary: #10737F;
  --complementary: #CFBE9A;
  --option: #6F6A61;
}

body{
  font-family: 'Poppins', sans-serif;
  background-color: rgba(232, 242, 229, 1);
  color: rgba(98, 98, 98, 1);
}

/* GIF */
.gif-size{
  width: 300px;
}

.gif-size-ai{
  width: 150px;
  padding-top: 10px;
}

/* Images & Icons */
.homeIcon{
  height: 30px;
  margin-bottom: 10px;
}

.discordIcon{
  height: 19px;
  margin-bottom: 7px;
}

.codingDorsLogo{
  width: 48px;
  height: 36px;
}

.bugsIcon{
  width: 114px;
}

.loadingAI{
  width: 81px;
}

/* Button next/previous exercise */
.btn-previous-next-exercise:active{
  background-color: rgb(68, 4, 153);
  border: rgb(68, 4, 153);
  color: #FFFFFF;
}

.btn-previous-next-exercise:focus {
  outline: none;
}

.btn-previous-next-exercise{
  width: 85px;
  height: 22.06px;
  margin: 0px 5px;
  background-color: #9847FF;
  border: #9847FF;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.btn-previous-next-exercise:hover{
  background-color: rgb(111, 0, 255);
  color: white;
}

/* Button next/previous exercise */
.btn-show-solution:active{
  background-color: rgb(68, 4, 153);
  border: rgb(68, 4, 153);
  color: #FFFFFF;
}

.btn-show-solution:focus {
  outline: none;
}

.btn-show-solution{
  width: 107px;
  height: 20px;
  margin: 0px 5px;
  background-color: #9847FF;
  border: #9847FF;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.btn-show-solution:hover{
  background-color: rgb(111, 0, 255);
  color: white;
}

/* Display the button to exercises in QuestionScreen */
.container-previous-next-exercise{
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* Button run & theory */
.btn-run:active {
  background-color: var(--option);
  border: var(--option);
  color: #FFFFFF;
}

.btn-run:focus {
  outline: none;
}

.btn-run{
  width: 108px;
  height: 28px;
  background: #FDB500;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  color: #626262;
  border: #FDB500;
}

.btn-run:hover{
  background-color: #ffa200;
  border: #ffa200;
  color: #626262;
}

/* LoadingAI */
.loading-ai-div{
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'center';
  align-items: 'center';
}

.loading-ai-icon-div{
  text-align: center;
}

.loading-ai-text{
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
color: #FFFFFF;
}

/* ChatGPT Answer */
.container-reponse-chat{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  height: 50%;
}

.response-chat-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
}

.container-answer-from-chat{
  width: 90%;
  max-height: 336px;
  overflow-y: scroll;
  background: #626262;
  border-radius: 12px;
  box-shadow: 3px 3px 0 2px #E8F2E5;
}

.answer-from-chat-text{
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: #FFFFFF;
  margin: 18px;
}

.code-block-response-chat{
  background-color: #3B432C;
  border-radius: 12px;
  border: #3B432C;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #FFFFFF;
  white-space: pre-wrap;
  overflow-x: auto;
  padding: 7px;
  margin-top: 16px;
  padding-bottom: 14px;
}

/* Scrollbar */
.always-show-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.always-show-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.always-show-scrollbar::-webkit-scrollbar-track {
  background: var(--primary);
}

.always-show-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.always-show-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Homepage */
.questions-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Alert to make website responsive for mobile */
.alert-danger{
  display: none;
}

/* Pop up Private Tutoring */
.popup-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-tutoring {
  position: relative;
  width: 828px;
  height: 464px;
  background: linear-gradient(196.7deg, #DCB30A -8.28%, #9847FF 103.59%);
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
}

.close-button-tutoring {
  position: absolute;
  width: 46px;
  height: 46px;
  right: 16px;
  top: 16px;
  background: rgba(98, 98, 98, 0.4);
  border: none;
  border-radius: 23px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.close-button-tutoring:hover {
  background: rgba(98, 98, 98, 0.8);
}

.close-button-tutoring:active {
  background: rgba(98, 98, 98, 1);
}

.close-button-tutoring p{
  font-size: 30px;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-text{
  position: absolute;
  width: 497px;
  height: 115px;
  left: 96px;
  top: 44px;
}

.popup-text h1{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 128%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.popup-text h1 strong{
  margin-left: 10px;
}

.popup-text h6{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 128%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.popup-tutoring-btn{
  position: absolute;
  width: 704px;
  height: 77.88px;
  left: 62px;
  top: 335px;
  background: #DCB30A;
  border-radius: 15px;
  border: 1px solid #DCB30A;
}

.popup-tutoring-btn:hover{
  background: #ffcc00;
  border: 1px solid #ffcc00;
}

.popup-tutoring-btn:active{
  background: #ac8a03;
  border: 1px solid #ac8a03;
}

.popup-tutoring-btn a{
  height: 77.88px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 6px;
}

.popup-tutoring-btn img{
  width: 53px;
  height: 53px;
}

.popup-tutoring-btn p{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #626262;
  margin-left: 10.86px;
}

.popup-tutoring-background-img{
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 23px 23px 0 0;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
  .screen-container{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: visible;
  }

  .alert-danger{
    display: block;
  }
  
  .unit-test-tbody, .container-unit-test, .unit-test-thead, .container-answer-from-chat{
    width: 90%;
    overflow-x: auto;
  }

  .unit-test-tbody{
    min-height: 150px;
    max-height: 150px;
  }

  .container-answer-from-chat{
    margin-bottom: 200px;
    width: 90%;
  }

  .btn-previous-next-exercise{
    width: 50px;
    height: 22.06px;
    margin: 0px 5px;
    font-size: 10px;
    line-height: 12px;
  }

  .btn-show-solution{
    width: 80px;
    height: 22.06px;
    margin: 0px 5px;
    font-size: 10px;
    line-height: 12px;
  }

  .btn-run{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 28px;
  }

  .answer-from-chat-text{
    font-size: 14px;
    margin: 18px;
  }

  .code-block-response-chat{
    font-size: 11px;
    padding: 7px;
  }

  /* Pop up Private Tutoring */
.popup-tutoring {
  width: 100%;
}

.popup-text{
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 90%;
  height: 100%;
  left: 25px;
  top: 44px;
}

.popup-text h1{
  font-size: 30px;
}

.popup-text h1 strong{
  margin-left: 5px;
}

.popup-text h6{
  font-size: 23px;
}

.popup-tutoring-btn{
  position: absolute;
  width: 90%;
  left: 20px;
}

}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
  .btn-previous-next-exercise{
    width: 100px;
    height: 30px;
    font-size: 16px;
    line-height: 20px;
  }

  .btn-show-solution{
    width: 150px;
    height: 30px;
    font-size: 16px;
    line-height: 20px;
  }

  .btn-run{
    font-size: 20px;
    width: 130px;
    height: 32px;
  }    

  .container-answer-from-chat{
    max-height: 2000px;
    min-height: 100px;
  }

  .response-chat-title, .title-video-solution, .compilation-title, .unit-test-th, .unit-test-title{
    font-size: 28px;
    line-height: 42px;
  }

  .answer-from-chat-text, .compilation-text{
    font-size: 20px;
  }

  .code-block-response-chat, .code-block-theory{
    font-size: 16px;
    line-height: 130%;
  }

  td, th.unit-test-td-title{
    font-size: 18px;
    line-height: 18px;
  }

  .btn-question-screen-div{
    margin: 30px 0;
  }

  .codingDorsLogo{
    width: 58px;
    height: 46px;
  }

  .modal{
    justify-content: flex-start;
  }

  .p-modal{
    font-size: 20px;
  }

  .modal-content h2{
    font-size: 32px;
  }

  .compilation-text-container{
    min-height: 10%;
    max-height: 400px;
    width: 80%;
  }

  .btn-modal{
    font-size: 20px;
    line-height: 130%;
    width: 253px;
    height: 48px;
  }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
  .btn-run{
    font-size: 28px;
    width: 160px;
    height: 45px;
  }

  .btn-previous-next-exercise{
    width: 130px;
    height: 40px;
    font-size: 24px;
    line-height: 130%;
  }

  .btn-show-solution{
    width: 200px;
    height: 40px;
    font-size: 24px;
    line-height: 130%;
  }

  .response-chat-title, .title-video-solution, .compilation-title, .unit-test-th, .unit-test-title{
    font-size: 34px;
    line-height: 130%;
  }

  .answer-from-chat-text, .compilation-text{
    font-size: 28px;
    line-height: 130%;
  }

  .code-block-response-chat{
    font-size: 22px;
    line-height: 130%;
    padding-bottom: 30px;
  }

  td, th.unit-test-td-title{
    font-size: 28px;
    line-height: 130%;
  }

  .p-modal{
    font-size: 24px;
  }

  .modal-content h2{
    font-size: 36px;
  }

  .code-block-theory{
    font-size: 18px;
    line-height: 130%;
    padding-top: 20px;
  }

  .compilation-text-container{
    min-height: 10%;
    max-height: 400px;
    width: 80%;
  }
}