.form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 243px 0 0;
}

.form-background {
    width: 428px;
    height: 612px;
    background-color: #FDB500;
    border-radius: 0 0 10px 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.paragraph {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.survey-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.label-form-card-survey {
    display: block;
    margin-bottom: 2px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    padding-top: 7px;
  }

  .form-card-survey-first-name {
    width: 368px;
    height: 57px;
    padding-left: 40px;
    background: url("../../../images/firstNameIcon.svg") no-repeat scroll 9px 15px;
    background-size: 20px;
    background-color: #9847FF;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.form-card-survey-first-name:hover {
    background-color: #8c34ff;
    transition-duration: 20ms;
}

.form-card-survey-email {
    width: 368px;
    height: 57px;
    padding-left: 40px;
    background: url("../../../images/emailIcon.svg") no-repeat scroll 8px 17px;
    background-size: 22px;
    background-color: #9847FF;
    border-radius: 5px;
    border-color: transparent;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.form-card-survey-email:hover {
    background-color: #8c34ff;
    transition-duration: 20ms;
}

.form-card-survey-first-name::placeholder,
.form-card-survey-email::placeholder {
    color: white;
    opacity: 70%;
}

.submit-button-container {
    margin: 29px 0;
}

.form-card-survey-button {
    width: 368px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 5px;
    border-color: transparent;
    font-size: 18px;
    font-family: 'Roboto Flex';
    color: #9847FF;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-card-survey-button:hover {
    background-color: #656565(156, 80, 255);
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .form-container {
        align-items: center;
        margin: 0 0 0 0;
        flex-direction: column;
    }

    .form-background {
        position: relative;
        width: 100%;
    }

    .survey-container {
        align-items: center;
    }

    .form-card-survey-first-name {
        width: 300px;
    }

    .form-card-survey-email {
        width: 300px;
    }

    .form-card-survey-button {
        width: 300px;
    }
}