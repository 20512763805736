.code-solutions-course-blank-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 350px;
    margin-bottom: 45px;
    background-color: #827B89;
    border-radius: 30px;
    font-size: 70px;
    color: white;
    transition: background-color ease-in-out 0.2s
}

.code-solutions-course-blank-card-container:hover {
    background-color: #8466a1;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .code-solutions-course-blank-card-container {
        display: none;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
    .code-solutions-course-blank-card-container {
        height: 600px;
    }
}