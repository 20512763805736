.code-solutions-course-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    width: 75%;
    height: 350px;
    margin-bottom: 45px;
    background-color: #7D54B0;
    border-radius: 30px;
    transition: background-color ease-in-out 0.2s
}

.code-solutions-course-card-container:hover {
    background-color: #9847FF;
}

.code-solutions-course-card-container p {
    font-size: 24px;
    line-height: 20px;
    margin: 0px;
    color: white;
    font-weight: 400;
}

.code-solutions-card-container {
    width: 80%;
    margin: 15px;
}

.code-solutions-card-image {
    width: 100%;
    border-radius: 20px;
}

.code-solutions-card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 50px;
    background-color: white;
    color: black;
    font-weight: 800;
    font-size: 20px;
    border: none;
    border-radius: 30px;
    transition: background-color ease-in-out 0.2s, width ease-in-out 0.3s, height ease-in-out 0.3s;
}

.code-solutions-card-button:hover {
    background-color: #EBB400;
    width: 73%;
    height: 53px;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .code-solutions-course-card-container {
        width: 85%;
        height: 300px;
    }

    .code-solutions-course-card-container p {
        font-size: 20px;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
    .code-solutions-course-card-container {
        height: 600px;
    }

    .code-solutions-course-card-container p {
        font-size: 40px;
    }

    .code-solutions-card-button {
        height: 70px;
        font-size: 30px;
    }
}