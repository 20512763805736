/* Week Detail Screen */
.week-details-screen{
  background-color: white;
  height: 100vh;
}

.container-week-detail{
    display: flex;
    flex-direction: column;
    margin: 20px 50px;
    justify-content: center;
  }
  
  .container-week-detail h1{
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: black;
  }
  
  .container-week-detail h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: rgba(152, 71, 255, 1);
  }

@media screen and (max-width: 700px) {
  /* Week Detail Screen */
  .week-details-screen{
    height: 100%;
  } 
}