.benefit-card-container-white {
    width: 70%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 5px 10px #00000020;
    transition: transform 0.2s;
}

.benefit-card-container-white:hover {
    transform: scale(1.02);
}

.benefit-card-container-dark {
    width: 70%;
    height: 80%;
    background-color: #626262;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 5px 10px #00000020;
    transition: transform 0.2s;
}

.benefit-card-container-dark:hover {
    transform: scale(1.02);
}

.fa-circle-check {
    color: green;
    margin-bottom: 15px;
    font-size: 60px;
    background-color: white;
    border-radius: 30px;
    border: 3px solid green;
}

.benefit-card-container-title-white {
    color: #000000;
    width: 75%;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 15px;
}

.benefit-card-container-body-white {
    color: rgb(0, 0, 0);
    width: 85%;
    font-size: 16px;
    text-align: center;
}


.benefit-card-container-title-dark {
    color: #ffffff;
    width: 75%;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 15px;
}

.benefit-card-container-body-dark {
    color: #ffffff;
    width: 85%;
    font-size: 16px;
    text-align: center;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .benefit-card-container-white {
        margin-bottom: 30px;
        height: 300px;
    }

    .benefit-card-container-dark {
        margin-bottom: 30px;
        height: 300px;
    }

    .benefit-card-container-title-white {
        font-size: 14px;
    }
    
    .benefit-card-container-body-white {
        font-size: 12px;
    }
    
    
    .benefit-card-container-title-dark {
        font-size: 14px;
    }
    
    .benefit-card-container-body-dark {
        font-size: 12px;
    }
}