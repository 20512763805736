.sales-page-testimony-card-container {
    width: 450px;
    height: 450px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 15px 10px 15px #0000002d;
}

.sales-page-testimony-card-name {
    font-size: 22px;
    color: #3D4CD8;
    font-weight: 700;
}

.sales-page-testimony-card-occupation {
    font-size: 20px;
    color: #3D4CD8;
    font-weight: 300;
    margin-bottom: 25px;
}

.sales-page-testimony-card-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-testimony-card-video {
    border-radius: 100px;
    height: 210px;
    width: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-page-testimony-cards-video-placeholder {
    width: 370px; /* Adjust as needed */
    height: 210px; /* Adjust as needed */
    background-color: #000; /* Placeholder background */
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .sales-page-testimony-card-container {
        width: 250px;
        height: 250px;
        margin-bottom: 30px;
    }

    .sales-page-testimony-card-name {
        font-size: 16px;
    }
    
    .sales-page-testimony-card-occupation {
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .sales-page-testimony-card-video {
        border-radius: 100px;
        height: 130px;
        width: 200px;
    }
}