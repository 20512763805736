/* Unit Card */
.unit-card-container{
    width: 603px;
    height: 102px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.unit-card-container-left{
    margin-left: 37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.unit-card-container-left h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 0px;
}

.unit-card-container-left h2{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 0px;
}

.unit-card-container-right{
    margin-right: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-lecture{
    border-radius: 20px;
    width: 114px;
    height: 52px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-lecture:active{
    box-shadow: none;
    height: 54px;
}

/* Week Button */
.week-button-format{
    width: 96px;
    height: 96px;
    border-radius: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    z-index: -1;
}

.week-button-format:active{
    box-shadow: none;
}

.icon-week-button{
    width: 50px;
}

.popup-week-unit{
    position: absolute;
    bottom: 0;   /* changed from 250px to 0 */
    left: -65px;
    transform: translateY(100%); /* add this line to shift the popup down by its own height */
    padding: 10px;
    width: 282px;
    height: 110px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 17px;
    z-index: 1;
}

.popup-week-unit h6{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.popup-week-unit p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
}

.popup-week-unit a button, .popup-week-unit button{
    width: 95%;
    height: 30px;
    border-radius: 5px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
}

.unit-content{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.unit-content-weeks{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}

.column-1{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 50px;
}

.column-2{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 100px;
}

.column-3{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 150px;
}

.column-4{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 200px;
}

.column-5{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 250px;
}

.column-6{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 300px;
}

.column-7{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 350px;
}

.column-8{
    width: calc(100% / 8);
    margin-right: 0;
    margin-left: 400px;
}

.icon-notes{
    width: 24px;
    padding-right: 5px;
    padding-left: -2px;
}

.div-contribute{
    display: flex;
    justify-content: center;
}

.btn-contribute{
    width: 200px;
    height: 200px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px;
    z-index: -1;
    color: #FFFFFF;
    background-color: #9847FF;
    border: #9847FF;
    box-shadow: 0 4px 0 2px #6621BD;
    font-size: 20px;
    font-weight: 600;
}

.btn-contribute:hover{
    background-color: #6621BD;
    box-shadow: none;
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
    /* Unit Card */
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .tree{
        margin: 0;
        padding: 0;
    }

    /* Unit Card */
    .unit-card-container{
        width: 100%;
        height: 80px;
    }

    .unit-card-container-left{
        margin: 0 16px;
    }
    
    .unit-card-container-left h1{
        font-size: 20px;
        line-height: 28px;
    }
    
    .unit-card-container-left h2{
        font-size: 14px;
    }
    
    .unit-card-container-right{
        margin-right: 16px;
    }
    
    .btn-lecture{
        width: 75px;
        height: 32px;
        font-size: 12px;
    }

    .btn-contribute{
        width: 150px;
        height: 150px;
        font-size: 16px;
        border-radius: 75px;
    }

    .icon-notes{
        width: 18px;
        padding-right: 5px;
        padding-left: -2px;
    }
    
    .btn-lecture:active{
        box-shadow: none;
        height: 54px;
    }

    /* Week Button */
    .week-button-format{
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }

    .icon-week-button{
        width: 25px;
    }

    .popup-week-unit{
        bottom: 0px; 
        width: 120px;
        height: 100px;
        padding-left: 16px;
        left: -15px;
    }
    
    .popup-week-unit h6{
        font-size: 12px;
    }
    
    .popup-week-unit p{
        font-size: 8px;
    }
    
    .popup-week-unit a button, .popup-week-unit button{
        height: 10px;
        font-size: 8px;
    }

    /* Columns */
    .column-1{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 10px;
    }
    
    .column-2{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 20px;
    }
    
    .column-3{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 30px;
    }
    
    .column-4{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 40px;
    }
    
    .column-5{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 50px;
    }
    
    .column-6{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 60px;
    }
    
    .column-7{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 70px;
    }
    
    .column-8{
        width: calc(100% / 8);
        margin-right: 0;
        margin-left: 80px;
    }
}

