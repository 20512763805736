:root{
    --primary: #3B432C;
    --secondary: #559E98;
    --tertiary: #10737F;
    --complementary: #CFBE9A;
    --option: #6F6A61;
}

/* Question Screen */
  /* Create the structure of the page QuestionScreen */
  .container-question-screen-file{
    display: flex;
    flex-direction: row;
    align-items: stretch;  /* add this property if all items are in one line */
    align-content: stretch;  /* add this property if items are wrapped in multiple lines */
    margin: 25px 50px 0 50px;
    justify-content: space-between;
    min-height: 50vh;
    height: 100%;
  }

  .btn-question-screen-title-solution{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .container-with-question-and-code, .container-with-answer-and-tests{
    display: flex;
    flex-direction: column;
    min-height: 83vh;
  }
  
  .container-with-question-and-code{
    width: 48%;
  }
  
  .container-with-answer-and-tests{
    margin-left: 14px;
    border-radius: 0px 12px 12px 12px;
    position: relative;
    z-index: 1;
    width: 50%;
    background-color: #9847FF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .container-with-answer-and-tests::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        180deg,
        #D9D9D9 0%,
        rgba(217, 217, 217, 0) 100%
      ),
      url('../../images/backgroundTestsAIDiv.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.1;
    z-index: -1; /* Make sure the content is in front of the background image */
  }
  
  /* Question Screen Left - Problem Statement and Code Editor */
  .question-title{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #626262;
  }
  
  .question-statement{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: left;
    color: #626262;
    white-space: "pre-wrap"
  }
  
  .btn-question-screen-div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 15px 0;
  }
  
  /* Btn to change theme of code editor */
  .change-theme-div{
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
  
  .paintIcon{
    height: 30px;
  }

  /* Loading bar */
  .loading-bar-div{
    width: 80%;
    height: 50%;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .loading-bar-div h6{
    color: white;
  }

  .loading-bar-progress{
    background-color: #DCB30A;
    height: 30px;
    border-radius: 5px;
  }

/* Mobile Screen */
@media screen and (max-width: 700px) {
    /* QuestionScreen */
  .container-question-screen-file{
    flex-direction: column;
    margin-top: 0px;
  }

  .container-with-question-and-code {
    margin: 0px;
    width: 100%;
  }

  .container-with-answer-and-tests{
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .question-title{
    font-size: 18px;
    line-height: 26px;
  }
  
  .question-statement{
    font-size: 14px;
    line-height: 130%;
  }
  
  
  /* Btn to change theme of code editor */
  .change-theme-div{
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  
  .paintIcon{
    height: 20px;
  }
}
  
/* Large Screen Version */
@media screen and (min-width: 1800px) {
    .container-question-screen-file{
      margin-left: 100px;
      margin-right: 100px;
    }
  
    .container-with-question-and-code, .container-with-answer-and-tests{
      width: 50%;
    }

    .question-title{
      font-size: 30px;
      line-height: 45px;
    }
  
    .question-statement{
      font-size: 22px;
      line-height: 130%;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
  .container-answer-from-chat{
    max-height: 2000px;
    min-height: 100px;
  }

  .question-title{
    font-size: 38px;
    line-height: 130%;
  }

  .question-statement{
    font-size: 28px;
    line-height: 130%;
  }
}