.landing-page-top-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.landing-page-dors-logo {
    width: 570px;
}

.landing-page-top-session-paragraph-1 {
    font-family: 'Poppins';
    font-size: 40px;
    line-height: 38px;
    font-weight: 200;
    color: #ffffff;
    text-align: right;
    margin: 15px;
}

.landing-page-top-session-paragraph-2 {
    color: #FDB500;
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 18px;
    font-weight: 800;
    text-align: right;
    margin: 10px 0 0 0;
}

.landing-page-top-session-paragraph-2 p:nth-child(2) {
    font-weight: 500;
}

.landing-page-top-session-paragraph-2-color {
    color: #ffffff;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .landing-page-top-info-container{
        align-items: center;
    }

    .landing-page-dors-logo {
        width: 300px;
    }

    .landing-page-top-session-paragraph-1 {
        text-align: center;
    }

    .landing-page-top-session-paragraph-2 {
        text-align: center;
        line-height: 45px;
    }
}