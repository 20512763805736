/* Exercises Box */
.exercises-box{
    width: 100%;
    min-height: 60vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    z-index: 1;
}
  
.exercises-box-header{
    width: 80%;
    height: 51px;
    background-color: rgba(152, 71, 255, 1);
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    text-align: center;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: rgba(152, 71, 255, 1);
}
  
.exercises-box-header p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
}
  
.exercises-box-body{
    width: 100%;
    height: 100%;
    background-color: #626262;
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin-top: -25px;
    z-index: -1;
    padding:30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
}
  
/* Exercise Card */
.exercise-card{
   width: 329px;
   height: 96px;
   display: flex;
   flex-direction: column;
   align-items: center;
}
  
.exercise-card-number{
    width: 54px;
    height: 54px;
    border-radius: 27px;
    background-color: #9847FF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.exercise-card-number p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #E9D9D9;
    padding-top: 15px;
}
  
.exercise-card:hover .exercise-card-number{
    background-color: #DCB30A;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
  
.exercise-card-title{
    width: 100%;
    height: 70px;
    background-color: #FFFFFF;
    border-radius: 12px;
    margin-top: -20px;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.exercise-card-title p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    padding-top: 20px;
}
  
.exercise-card:hover .exercise-card-title{
    background-color: #FFFFFF;
}

.proficiency-card-position{
    margin-bottom: 0;
    margin-top: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.proficiency-card{
    width: 497px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    margin-top: -20px;
    z-index: -1;
    cursor: pointer;
}

.proficiency-card-circle-icon{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 54px;
    height: 54px;
    border-radius: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.proficiency-card-circle-icon:hover{
    background: #FDB500;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.proficiency-card-circle-icon img{
    width: 32px;
    height: 32px;
}

.proficiency-card-title{
    width: 100%;
    height: 70px;
    background: linear-gradient(117.25deg, #DCB30A 6.24%, #9847FF 110.09%);
    border-radius: 12px;
    margin-top: -20px;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.proficiency-card-title:hover{
    background: linear-gradient(246.64deg, #DCB30A 12.49%, #9847FF 101.61%);
}
  
.proficiency-card-title p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding-top: 20px;
}

@media screen and (max-width: 700px) {
    /* Week Detail Screen */
    .exercises-box{
        height: 100%;
        width: 100%;
    }

    .exercises-box-body{
        align-items: flex-start;
    }

    /* Exercise Card */
    .exercise-card{
        width: 200px;
    }
    
    .exercise-card-number{
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
    
    .exercise-card-number p{
        font-size: 20px;
        padding-top: 15px;
    }
    
    .exercise-card-title p{
        font-size: 16px;
    }
    
    .proficiency-card{
        width: 200px;
        margin-top: -30px;
    }
    
    .proficiency-card-circle-icon{
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
    
    .proficiency-card-circle-icon img{
        width: 26px;
        height: 26px;
    }
    
    .proficiency-card-title p{
        font-size: 18px;
    }

    .exercises-box-header p{
        font-size: 20px;
        line-height: 30px;
    }
}