/* Compilation Error */
.compilation-error-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 50%;
  }
  
  .compilation-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: 10px 0;
  }
  
  .compilation-text-container{
    width: 540px;
    min-height: 5%;   /* Add a minimum height */
    max-height: 200px;  /* Add a maximum height */
    background: #626262;
    border-radius: 12px;
    padding: 1px;      /* Add padding to the container */
    overflow: auto;     /* Enable scrolling when content exceeds the container height */
  }
  
  .compilation-text{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    padding: 1px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    word-wrap: break-word; /* Wrap text to fit the container width */
  }

  .yellow-text{
    color: rgba(253, 181, 0, 1);
  }

/* Mobile Version */
@media screen and (max-width: 700px) {
  .compilation-title{
    font-size: 18px;
    line-height: 30px;
    margin: 10px 0;
  }
  
  .compilation-text-container{
    width: 90%;
    min-height: 5%;   /* Add a minimum height */
    border-radius: 12px;
    padding: 1px;      /* Add padding to the container */
    margin-bottom: 20px;
  }
  
  .compilation-text{
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    padding: 1px;
  }
}