.inner-code-solutions-card-line-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.inner-code-solutions-card-line-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 320px;
    background-color: #626262;
}

.inner-code-solutions-card-line-row-even {
    background-color: #626262;
}

.inner-code-solutions-card-line-row-odd {
    background-color: #5A5A5A;
}


.inner-code-solutions-card-line-row-video {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
    width: 30%;
}

.inner-code-solutions-card-line-row-video-placeholder {
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
}

.inner-code-solutions-card-line-row-youtube-video {
    width: 100%;
    height: 280px;
    border-radius: 20px;
}

.inner-code-solutions-card-line-row-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    padding: 40px;
    color: white;
}

.inner-code-solutions-card-line-row-name p:nth-child(1) {
    font-size: 52px;
    font-weight: 700;
    line-height: 65px;
}

.inner-code-solutions-card-line-row-name p:nth-child(2) {
    font-size: 34px;
    font-weight: 400;
    line-height: 45px;
}

.inner-code-solutions-card-line-row-button-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 35%;
    height: 100%;
    background-color: #9847FF;
    border-bottom: 1px solid #5A5A5A;
    cursor: pointer;
    transition: width ease-in-out 0.3s;
}

.inner-code-solutions-card-line-row-button-container:hover {
    width: 40%;
}

.inner-code-solutions-card-line-row-button-container p {
    font-size: 40px;
    font-weight: 700;
    color: white;
    padding-top: 20px;
}

.inner-code-solutions-card-line-row-button-container img {
    padding: 10px;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .inner-code-solutions-card-line-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .inner-code-solutions-card-line-row-video {
        width: 80%;
        padding-left: 0px;
        padding: 20px;
    }

    .inner-code-solutions-card-line-row-name {
        text-align: center;
        padding: 0px;
        width: 90%;
    }

    .inner-code-solutions-card-line-row-name p:nth-child(1) {
        font-size: 25px;
        line-height: 10px;
    }

    .inner-code-solutions-card-line-row-name p:nth-child(2) {
        font-size: 20px;
        line-height: 30px;
    }

    .inner-code-solutions-card-line-row-button-container {
        width: 100%;
        height: 150px;
        transition: height ease-in-out 0.3s;
    }

    .inner-code-solutions-card-line-row-button-container:hover {
        width: 100%;
        height: 160px;
    }

    .inner-code-solutions-card-line-row-button-container p {
        font-size: 20px;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
    .inner-code-solutions-card-line-row {
        height: 520px;
    }

    .inner-code-solutions-card-line-row-name p:nth-child(1) {
        font-size: 70px;
        line-height: 65px;
    }

    .inner-code-solutions-card-line-row-name p:nth-child(2) {
        font-size: 60px;
        line-height: 65px;
    }

    .inner-code-solutions-card-line-row-button-container p {
        font-size: 60px;
    }

    .inner-code-solutions-card-line-row-button-container img {
        width: 15%;
        padding: 10px;
    }
}