/* BlogPage and BlogCard */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.blog-page {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #bcb96a, #6621bd 50%, #bcb96a);
  font-family: "Poppins", sans-serif;
  animation: fadeInBackground 1s ease-in-out;
}

/* Fade-in animation for the background */
@keyframes fadeInBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.blog-logo {
  width: 180px;
  height: auto;
  margin-top: 20px;
  animation: bounceLogo 1s ease-in-out 0.5s forwards;
  opacity: 0;
}

/* Bounce effect for logo */
@keyframes bounceLogo {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  60% {
    transform: translateY(5px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.blog-title {
  font-size: 2rem;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 300;
  text-align: center;
  opacity: 0;
  animation: fadeInText 1s ease-in-out 0.5s forwards;
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.blog-list {
  width: 100%;
  max-width: 600px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.blog-card {
  background-color: #6621bd;
  color: #ffffff;
  width: 100%;
  max-width: 550px;
  margin: 15px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(102, 33, 189, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInCard 0.6s ease-out forwards;
}

@keyframes fadeInCard {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.blog-card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25), 0 8px 20px rgba(102, 33, 189, 0.4);
}

.post-featured-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.post-featured-image img:hover {
  transform: scale(1.02); /* Slight zoom on hover */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.post-content {
  padding: 10px 0;
}

.post-title a {
  font-size: 1.3rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.post-title a:hover {
  color: #ff6b6b;
  transform: translateX(5px);
}

.post-author {
  font-size: 0.9rem;
  color: #faca52;
  margin: 5px 0;
}

.post-excerpt {
  font-size: 1rem;
  color: #ffffff;
  margin: 10px 0;
  line-height: 1.6;
}

.read-more {
  display: inline-flex;
  align-items: center;
  color: #ef4953;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.read-more:hover {
  color: #ff6b6b;
  transform: translateX(5px);
}

.read-more svg {
  margin-left: 5px;
}

/* New Post button for Admins */

.new-blog-btn {
  position: absolute;
  top: 105px;
  left: 30px;
  background-color: #6621bd;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 10;
}

.new-blog-btn:hover {
  background-color: #50179f;
  transform: scale(1.05);
}

.new-blog-btn:focus {
  outline: none;
}

/* Scroll-to-Bottom Button */
.scroll-bottom-btn {
  position: fixed;
  bottom: 40px;
  right: 108px;
  background-color: #6621bd;
  color: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
}

.scroll-bottom-btn:hover {
  background-color: #50179f;
  transform: scale(1.1);
}

.scroll-bottom-btn:focus {
  outline: none;
}

/* Scroll-to-Top Button */
.scroll-top-btn {
  position: fixed;
  bottom: 40px; /* Align with the scroll-to-bottom button */
  right: 175px; /* Shift to the left of the bottom button */
  background-color: #6621bd;
  color: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
}

.scroll-top-btn:hover {
  background-color: #50179f;
  transform: scale(1.1);
}

.scroll-top-btn:focus {
  outline: none;
}

/* Ensure proper positioning for mobile */
@media screen and (max-width: 768px) {
  .new-blog-btn {
    position: static;
    margin: 10px auto;
    display: block;
  }
}
/* Responsive adjustments And scroll down button */
@media screen and (max-width: 800px) {
  .blog-card {
    max-width: 100%;
  }

  .blog-title {
    font-size: 1.8rem;
  }
  .scroll-bottom-btn,
  .scroll-top-btn {
    display: none;
  }
}

@media screen and (min-width: 1800px) {
  .blog-card {
    max-width: 500px;
    font-size: 1.1rem;
  }

  .post-excerpt {
    font-size: 1.1rem;
  }
}
