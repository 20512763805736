.landing-page{
    background-color: #fff;
    width: 100%;
    height: 100%;
}

.btn-icon-landing-page{
    width:21px;
    height:17px;
}

.homepage-video{
    width: 40%;
    height: 85%;
}

/* First Section */
.landing-page-first-section{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 600px;
    flex-direction: row;
}

.landing-page-first-section-text{
    width: 30%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.landing-page-first-section-image{
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 0;
}

.landing-page-first-section-image-mascot {
    width: 190%;
    transform: rotate(7deg);
}

.landing-page-first-section-middle-division {
    width: 10%;
}

.background-landing-page{
    position: relative;
    width: 100%;
    height: 180px;
    opacity: 50%;
    background-image: url('../../images/backgroundLandingPage.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.h1-landing-page{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 50px;
    text-align: right;
    white-space: pre-line;
    color: #9847FF;
}

.h1-landing-page-color {
    color: #626262;
    text-align: right;
    white-space: pre-line;
}

.h2-landing-page{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 30px;
    text-align: right;
    white-space: pre-line;
    line-height: 170%;
    color: black;
    margin: 14px 0 25px 0;
}

.btn-landing-page-start-now{
    background-color: #626262;
    font-family: 'Roboto Flex';
	color: #E8F2E5;
    width: 415px;
	height: 60px;
	border: none;
	border-radius: 10px;
	font-size: 26px;
    font-weight: 800;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 30px;
    margin: 0 0 15px 0;
    z-index: 3;
}

.btn-landing-page-already-started{
    background-color: #9847FF;
    font-family: 'Roboto Flex';
	color: #E8F2E5;
    width: 415px;
	height: 60px;
	border: none;
	border-radius: 10px;
	font-size: 26px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 30px;
}

.btn-landing-page-start-now:hover{
    background-color: #595959;
}

.btn-landing-page-start-now:active{
    background-color: #595959;
}

.btn-landing-page-already-started:hover{
    background-color: #903aff;
}

.btn-landing-page-already-started:active{
    background-color: #903aff;
}


/* Second Section */
.landing-page-second-section{
    background-color: #9847FF;
    margin: 0 auto;
    height: 850px;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-page-second-section::before{
    content: "";
    position: absolute;
    margin: 0 auto;
    height: 890px;
    width: 100%;
    z-index: -1;
    background-image: url("../../images/backgroundLandingPage.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 40px;
}

.card-grid-landing-page{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px 100px 0 100px;
    z-index: 2;
}

/* Third Section */
.landing-page-third-section{
    padding: 90px 0;
    width: 100%;
    height: 800px;
    background: #DCB30A;
    display: flex;
    align-items: left;
    text-align: center;
    flex-direction: row;
}

.landing-page-third-section-left{
    width: 35%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 154px;
}

.landing-page-third-section-right{
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.h3-landing-page{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    display: flex;
    text-align: left;
    color: #000000;
}

.h4-landing-page{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    display: flex;
    text-align: left;
    color: #000000;
    margin: 24px 0 33px 0;
}

.btn-landing-page-purple{
    background-color: rgba(152, 71, 255, 1);
	color: white;
    width: 269px;
	height: 47px;
	border: none;
	border-radius: 10px;
	font-size: 18px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 30px;
}

.btn-landing-page-purple:hover{
    background-color: rgb(111, 0, 255);
}

.btn-landing-page-purple:active{
    background-color: rgb(68, 4, 153);
}

.video-landing-page{
    width: 600px;
    height: 500px;
    border-radius: 15px;
}

.image-landing-page{
    height: 100%;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .landing-page-first-section{
        flex-direction: column;
        margin: 0 auto;
        background-size: cover;
        margin-top: 0px;
        height: 1000px;
    }

    .landing-page-first-section-image-mascot {
        position: relative;
        margin: -20px 0 0 -35px;
        width: 500px;
    }

    .landing-page-first-section-image {
        align-items: center;
        justify-content: center;
    }

    .landing-page{
        width: 100%;
        margin: 0 auto;
        background-size: cover;
    }

    .background-landing-page{
        display: none;
        height: 20px;
    }

    .h1-landing-page{
        font-size: 40px;
    }

    .h2-landing-page{
        font-size: 28px;
        text-align: center;
    }

    .landing-page-first-section-image{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding-left: 20px;
        margin-top: -100px;
    }

    .landing-page-first-section-text{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        margin: 0 auto;
        z-index: 2;
        margin-top: -140px;
        width: 100%;
    }

    .btn-landing-page-start-now{
        width: 300px;
        height: 50px;
        border-radius: 5px;
        padding: 10px 10px;
    }

    .btn-landing-page-already-started {
        width: 300px;
        height: 50px;
        border-radius: 5px;
        padding: 10px 10px;
        font-size: 20px;
    }

    /* Mobile Version - Second Section*/

    .landing-page-second-section{
        height: 100%;
        margin-top: -40px;
    }

    .landing-page-second-section::before{
        height: 104%;
        margin-top: 0px;
    }

    .card-grid-landing-page{
        margin: 0px 80px 0 80px;
        justify-content: center;
    }

    .video-landing-page{
        width: 335px;
        height: 180px;
    }

    /* Mobile Version - Third Section*/

    .landing-page-third-section{
        flex-direction: column;
        height: 100%;
    }

    .landing-page-third-section{
        margin: 10px 0px;
    }

    .landing-page-third-section-left{
        padding-left: 00px;
        width: 100%;
        margin-top: 20px;
        align-items: center;
    }

    .landing-page-third-section-right{
        width: 100%;
    }
    
    .h3-landing-page{
        line-height: 20px;
        font-size: 20px;
    }

    .h4-landing-page{
        font-size: 20px;
        width: 90%;
    }

    .btn-landing-page-purple{
        width: 200px;
        height: 40px;
        border-radius: 5px;
        font-size: 14px;
        padding: 20px 20px;
    }

    .image-landing-page{
        padding-top: 40px;
        height: 350px;
    }

    .btn-icon-landing-page{
        width:17px;
        height:12px;
    }
}

/* Large Screen */
@media screen and (min-width: 1800px) {
    .landing-page-first-section{
        margin-left: 50px;
        margin-right: 50px;
        height: 800px;
    }

    .landing-page-first-section-image-mascot {
        width: 1800px;
        margin: -80px -500px;
    }

    .landing-page-first-section-text{
        margin-top: 30px;
        z-index: 3;
        width: 50%;
    }

    .landing-page-first-section-text {
        padding-bottom: 250px;
    }

    .background-landing-page{
        height: 300px;
        opacity: 70%;
    }
    
    .h1-landing-page{
        font-size: 56px;
    }

    .h2-landing-page{
        font-size: 40px;
        margin: 30px 0 40px 0;
    }

    .btn-landing-page-start-now{
        width: 520px;
        height: 60px;
        font-size: 34px;
        padding: 30px 30px;
    }

    .btn-landing-page-already-started{
        width: 520px;
        height: 60px;
        font-size: 34px;
        padding: 30px 30px;
    }

    .video-landing-page{
        width: 780px;
        height: 500px;
    }

    /* Large Screen - Second Section */

    .landing-page-second-section{
        height: 1250px;
    }

    .landing-page-second-section::before{
        height: 1310px;
        margin-top: 58px;
    }

    .card-grid-landing-page{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 50px 50px 0 50px;
        z-index: 2;
    }

    /* Large Screen - Third Section */

    .landing-page-third-section{
        height: 1000px;
    }
    
    .h3-landing-page{
        font-size: 50px;
    }

    .h4-landing-page{
        font-size: 38px;
        margin: 30px 0 50px 0;
    }

    .btn-landing-page-purple{
        width: 390px;
        height: 35px;
        font-size: 28px;
        padding: 40px 40px;
    }

    .btn-icon-landing-page{
        width:41px;
        height:37px;
    }
}

/* X-Large Screen Version */
@media screen and (min-width: 2500px) {
    .landing-page-first-section{
        margin-left: 50px;
        margin-right: 50px;
        height: 1250px;
    }
    
    .landing-page-first-section-text {
        padding-bottom: 450px;
        width: 50%;
    }

    .landing-page-first-section-image-mascot {
        width: 2600px;
        margin: -80px -600px;
    }

    .landing-page-first-section-video{
        margin-top: 60px;
        margin-right: -10px;
        z-index: 3;
    }

    .background-landing-page{
        height: 400px;
    }
    
    .h1-landing-page{
        font-size: 80px;
    }

    .h2-landing-page{
        font-size: 70px;
        margin: 50px 0 60px 0;
    }

    .btn-landing-page-start-now{
        width: 800px;
        height: 130px;
        font-size: 60px;
        padding: 30px 30px;
        margin: 0 0 40px 0;
    }

    .btn-landing-page-already-started{
        width: 800px;
        height: 130px;
        font-size: 60px;
        padding: 30px 30px;
    }

    .video-landing-page{
        width:  1153px;
        height: 920px;
    }

    /* X - Large - Second Section */

    .landing-page-second-section{
        height: 1800px;
    }

    .landing-page-second-section::before{
        height: 1850px;
    }

    /* X - Large - Third Section */

    .landing-page-third-section{
        height: 1700px;
    }

    .landing-page-third-section-left{
        width: 40%;
    }

    .landing-page-third-section-right{
        width: 60%;
    }

    .h3-landing-page{
        font-size: 80px;
        line-height: 100px;
    }

    .h4-landing-page{
        font-size: 70px;
        margin: 30px 0 70px 0;
        line-height: 90px;
    }

    .btn-landing-page-purple{
        width: 700px;
        height: 100px;
        font-size: 50px;
        padding: 40px 40px;
    }
}

/* New Homepage */
.homepage-first-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 100%;
    padding: 0;
    margin: 0;
    margin-top: -50px;
    z-index: 10;
}

.homepage-first-section-left{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.homepage-first-section-left img{
    width: 783px;
    height: 441px;
}

.homepage-first-section-right{
    width: 50%;
    min-height: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    margin: 0;
}

.homepage-first-section-right h1{
    font-family: Poppins;
    font-size: 34px;
    font-weight: 700;
    line-height: 51px;
    text-align: center;  
    color: #9847FF;
}

.homepage-first-section-right span{
    color: #626262;
}

.homepage-first-section-right h2{
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 35.64px;
    text-align: center;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.homepage-first-section-right a button{
    width: 269px;
    height: 47px;
    background-color: #9847FF;
    border: 1px solid #9847FF;
    border-radius: 10px;
    color: white;
    font-family: Roboto Flex;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    text-align: center;
}

.homepage-first-section-right a button:hover{
    background-color: rgb(111, 0, 255);
}

.homepage-second-section{
    width: 100%;
    height: 100%;
    background-color: #9847FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homepage-social-proof{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid white;
}

.homepage-social-proof img{
    width: 70px;
}

.homepage-social-proof h3{
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    color: white;
    margin: 0;
}

.homepage-social-proof h3 span{
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;    
    color: #D1ADFF;    
}

.homepage-youtube-videos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 30px 0;
}

.homepage-video{
    width: 318px;
    height: 180px;
}

.homepage-comments-div{
    width: 318px;
    background: linear-gradient(180deg, #6500E6 21.19%, #9847FF 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}

.homepage-comment-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.homepage-comment-container-left{
    width: 28px;
    border-radius: 14px;
    background: #4B4B4B70;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0;
    margin: 0;
    margin-right: 5px;
}

.homepage-comment-container-right{
    width: 262px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.homepage-comment-container-right h5{
    font-family: Poppins;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    color: white;    
}

.homepage-comment-container-right h6, .homepage-comment-container-right h5 span{
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    text-align: left;
    color: white;    
}

.homepage-comment-container-right p{
    font-family: Poppins;
    font-size: 8px;
    font-weight: 300;
    line-height: 9px;
    text-align: left;
    color: white;    
}

.homepage-testimonial-section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
}

.homepage-testimonial-section-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}

.homepage-testimonial-section-left div{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homepage-testimonial-section-left div h5{
    font-family: Poppins;
    font-size: 34px;
    font-weight: 700;
    line-height: 51px;
    text-align: center;
    color: #000000;    
    margin-bottom: 0;
    padding-bottom: 0;
}

.homepage-testimonial-section-left div h6{
    font-family: Poppins;
    font-size: 34px;
    font-weight: 400;
    line-height: 51px;
    text-align: center;
    color: #000000;
    margin-top: 0;
    padding-top: 0;    
}

.homepage-testimonial-section-left p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: justified;
    color: #000000;
    width: 70%;
    padding: 20px 0;   
}

.homepage-testimonial-section-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.homepage-testimonial-video{
    width: 500px;
    height: 300px;
}

.homepage-testimonial-placeholder{
    width: 477px;
    height: 378px;
    cursor: pointer;
}

.homepage-video-placeholder{
    cursor: pointer;
}

/* Mobile Version */
@media screen and (max-width: 700px) {
    .homepage-first-section{
        flex-direction: column;
        margin: 0;
    } 

    .homepage-first-section-left{
        width: 100%;
    }

    .homepage-first-section-left img{
        width: 400px;
        height: 100%;
    }

    .homepage-first-section-right{
        width: 100%;
        min-height: 400px;
    }

    .homepage-youtube-videos{
        flex-direction: column;
        justify-content: center;
    }

    .homepage-social-proof{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid white;
    }

    .homepage-testimonial-section{
        flex-direction: column;
        justify-content: center;
    }

    .homepage-testimonial-section-left, .homepage-testimonial-section-right{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .homepage-testimonial-video{
        width: 90%;
        height: 250px;
    }
    
    .homepage-testimonial-placeholder{
        width: 90%;
        height: 300px;
        cursor: pointer;
    }
}