/* Component when we pass all tests */
.container-all-tests-passed{
  max-height: 50%;
  min-height: 5%;
  min-width: 5%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 41%;
  background: #626262;
  border-radius: 12px;
  margin-top: 20px;
  box-shadow: 3px 3px 0 2px #FDB500;
}

.div-show-paragraph, .div-show-paragraph-msg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.svg-congrats{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 217px;
  height: 30px;
  margin-top: 15px;
}

.p-congrats{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
}

.p-passed-tests{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.title-video-solution{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin: 10px 0;
}

.video-wrapper {
  position: relative;
  display: inline-block;
  border-radius: 12px;
  width: 100%;
  padding-bottom: 20px;
}

.video-solution{
  width: 100%;
  height: 330px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
}

.playButton {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

/* Mobile Screen */
@media screen and (max-width: 700px) {
  .container-all-tests-passed{
    max-height: 50%;
    min-height: 5%;
    min-width: 5%;
    height: 250px;
    width: 100%;
  }

  .svg-congrats{
    width: 150px;
    height: 30px;
    margin-top: 15px;
  }
  
  .p-congrats{
    font-size: 14px;
    line-height: 14px;
  }
  
  .p-passed-tests{
    font-size: 12px;
    line-height: 12px;
  }

  .video-solution{
    width: 100%;
    height: 200px;
  }
}

/* Large Screen Version */
@media screen and (min-width: 1800px) {
  .container-all-tests-passed{
    height: 35%;
  }

  .svg-congrats{
    width: 320px;
    height: 60px;
    margin-top: 15px;
  }
  
  .p-congrats{
    font-size: 30px;
    line-height: 30px;
  }
  
  .p-passed-tests{
    font-size: 28px;
    line-height: 28px;
  }

  .div-show-paragraph{
    width: 100%;
    height: 100%;
  }

  .video-wrapper {
    width:100%;
  }

  .title-video-solution{
    font-size: 36px;
    line-height: 130%;
    margin: 5px 0 0 0;
  }

}